import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export const CheckBRNExistDocument = gql`
  query checkBRNExist($brn: String!) {
    checkBRNExist(brn: $brn)
  }
`;

export function useCheckBRNExistLazyQuery(baseOptions) {
  return useLazyQuery(CheckBRNExistDocument, baseOptions);
}
