export default {
  root: {},
  paper: {
    minWidth: 375,
    borderRadius: 8,
    boxShadow: "0 6px 6px 0 rgba(69, 90, 100, 0.1)",
    border: "1px solid rgba(69, 90, 100, 0.06)"
  },
  content: {
    padding: 20
  }
};
