import { Button as MuiButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const useStyles = makeStyles(theme => ({
  root: {},
  contained: {
    color: props => {
      if (props.color === "normal") {
        return theme.palette.text.secondary;
      }
      return theme.palette.primary.contrastText;
    },
    background: props => {
      if (props.color === "primary") return theme.palette.primary.main;
      if (props.color === "secondary") return theme.palette.secondary.main;
      if (props.color === "warning") return theme.palette.warning.main;
      if (props.color === "normal") return theme.palette.normal.main;
      return theme.palette.primary.main;
    },
    "&:hover": {
      background: props => {
        if (props.color === "primary") return theme.palette.primary.light;
        if (props.color === "secondary") return theme.palette.secondary.light;
        if (props.color === "warning") return theme.palette.warning.light;
        if (props.color === "normal") return theme.palette.normal.light;
        return theme.palette.primary.light;
      }
    }
  },
  text: {
    color: props => {
      if (props.color === "primary") return theme.palette.primary.main;
      if (props.color === "secondary") return theme.palette.secondary.main;
      if (props.color === "warning") return theme.palette.warning.main;
      if (props.color === "normal") return theme.palette.normal.main;

      return theme.palette.text.secondary;
    },
    background: props => {
      return "none";
    }
  },
  outlined: {
    color: props => {
      if (props.color === "selected") return "#ffffff";
      return theme.palette.text.secondary;
    },
    background: props => {
      return "none";
    }
  }
}));

const useCustomStyles = makeStyles(() => ({
  ellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  arabic_fix: {
    direction: "rtl",
    textAlign: "left"
  },
  none__ellipsis: {
    whiteSpace: "unset"
  }
}));
const Button = forwardRef((props, ref) => {
  const { color, variant = "contained", noneEllipsis, ...other } = props;
  const classes = useStyles(props);
  const customClasses = useCustomStyles();
  return (
    <MuiButton
      ref={ref}
      classes={classes}
      variant={variant}
      className={customClasses.ellipsis}
      {...other}
      children={
        <span
          className={clsx(customClasses.ellipsis, {
            [customClasses.none__ellipsis]: noneEllipsis
          })}
        >
          {props.children}
        </span>
      }
    />
  );
});

Button.propTypes = {
  /** 버튼 색상 */
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "selected",
    "normal",
    "sub",
    "icon"
  ]),
  /** 버튼 타입 */
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  /** 버튼 크기 */
  size: PropTypes.oneOf(["medium", "small", "large"]),
  /** 비활성화 */
  disabled: PropTypes.bool
};

Button.defaultProps = {
  color: "primary",
  variant: "contained",
  size: "medium",
  disabled: false
};

export default Button;
