const ToastErrorIcon = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-9 4v-2h2v2H9zm0-8v5h2V6H9z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ToastErrorIcon;
