const DIALOG_SIZES = {
  SMALL: {
    value: "small",
    localeKey: "size.small",
    width: 500,
    height: 500,
  },
  MEDIUM: {
    value: "medium",
    localeKey: "size.medium",
    width: 800,
    height: 800,
  },
  LARGE: {
    value: "large",
    localeKey: "size.large",
    width: 1200,
    height: 800,
  }
};

const NOTICE_TYPES = {
  NOTICE: "notice",
  POPUP: "popup"
};

export { DIALOG_SIZES, NOTICE_TYPES };
