import { IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { SnackbarProvider, useSnackbar } from "notistack";
import CloseIcon from "theme/icons/Close.icon";
import ToastErrorIcon from "theme/icons/ToastError.icon";
import ToastSuccessIcon from "theme/icons/ToastSuccess.icon";

const useStyles = makeStyles(theme => ({
  root: {
    "& *": {
      boxSizing: "border-box"
    }
  },
  topRight: {
    top: 58,
    right: 10
  },
  snackbar: {
    width: 380,
    justifyContent: "center",
    backgroundColor: `#263238 !important`,
    borderRadius: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "SUIT Variable",
    paddingLeft: 16
  },
  action__btn: {
    padding: 0,
    color: "white",
    marginRight: 8
  },
  icon: {
    marginRight: 16
  },
  close__icon: {
    color: "#fff"
  },
  success__icon: {
    color: "#00bfa5"
  },
  error__icon: {
    color: "#ff8585"
  }
}));

const CommonCloseAction = ({ actionKey }) => {
  const classes = useStyles();

  const { closeSnackbar } = useSnackbar();

  const close = () => closeSnackbar(actionKey);

  return (
    <IconButton className={classes.action__btn} onClick={close}>
      <CloseIcon className={clsx(classes.close__icon)} />
    </IconButton>
  );
};

export const ToastProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={1500}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      classes={{
        containerRoot: classes.root,
        containerAnchorOriginTopRight: classes.topRight,
        variantSuccess: classes.snackbar,
        variantError: classes.snackbar,
        variantInfo: classes.snackbar,
        variantWarning: classes.snackbar
      }}
      TransitionProps={{ direction: "down" }}
      preventDuplicate
      action={key => <CommonCloseAction actionKey={key} />}
      iconVariant={{
        success: (
          <ToastSuccessIcon
            className={clsx(classes.icon, classes.success__icon)}
          />
        ),
        error: (
          <ToastErrorIcon className={clsx(classes.icon, classes.error__icon)} />
        )
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
