import React from "react";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Grid, Toolbar, useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { CCTypography } from "styles/components";
import { SmallSizeInfo, MiddleSizeInfo, FullSizeInfo } from "./components";

const useStyles = makeStyles(theme => ({
  height: { height: "100%" },
  root: {
    height: theme.mixins.topbar.height,
    backgroundColor: "white",
    boxShadow: "none",
    borderBottom: `2px solid ${theme.palette.input.hover}`,
    color: theme.palette.common.black,
    minWidth: 500,
    left: 0
  },
  toolbar: {
    height: "100%",
    padding: 0
  },
  container: {
    height: "100%"
  },
  left__item: {
    width: 80,
    height: "100%"
  },
  left__item__container: {
    justifyContent: "center",
    alignContent: "center",
    height: "100%"
  },
  left__item__container__icon: {
    color: "#0097a7",
    cursor: "pointer"
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingLeft: 18
  },
  right__item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
    flexGrow: 1
  }
}));

const TopBar = props => {
  const { onOpen } = props;
  const classes = useStyles();

  const middleSizeUp = useMediaQuery("(min-width:870px)");
  const fullSizeUp = useMediaQuery("(min-width:1400px)");

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.container}>
          <Grid item className={classes.left__item}>
            <Grid container className={classes.left__item__container}>
              <MenuIcon
                className={classes.left__item__container__icon}
                onClick={onOpen}
              />
            </Grid>
          </Grid>

          <Grid item className={classes.title}>
            <CCTypography variant="h2">Clever Lab Manager</CCTypography>
          </Grid>

          <Grid item className={classes.right__item}>
            {!middleSizeUp && !fullSizeUp && <SmallSizeInfo />}
            {middleSizeUp && !fullSizeUp && <MiddleSizeInfo />}
            {fullSizeUp && <FullSizeInfo />}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
