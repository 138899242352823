import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CCSearchTextField, translate } from "components";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCTextField } from "styles/components";
import { SearchIcon } from "styles/icons";
import {
  ACCOUNT_TYPE_VALUES,
  REGISTRATION_FILTER_STATUS
} from "types/registrationManage";

import { makeList } from "types";

const useStyles = makeStyles(theme => ({
  input: { backgroundColor: theme.palette.common.white },
  searchtext__field: {
    width: 300
  },
  ml8: {
    marginLeft: 8
  },
  selection: {
    width: 142
  }
}));

const RegistrationFilter = props => {
  const { filter, onSearch } = props;

  const classes = useStyles();
  const [value, setValue] = useState(filter);

  const intl = useIntl();

  const inputRef = useRef();

  const customerStatusList = makeList(REGISTRATION_FILTER_STATUS);
  const accountTypeList = makeList(ACCOUNT_TYPE_VALUES);

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  useEffect(() => {
    handleOnSearch();
  }, [value.accountType, value.status]);

  const handleOnSearch = () => {
    onSearch({ ...value, searchText: inputRef.current.value });
  };

  return (
    <>
      <CCSearchTextField
        className={classes.searchtext__field}
        variant="outlined"
        label={"Lab ID, Name, Tel No."}
        margin="dense"
        inputRef={inputRef}
        value={value.searchText}
        onKeyPress={e => {
          if (e.key === "Enter") {
            handleOnSearch();
          }
        }}
        onChange={e => {
          setValue(prevState => ({ ...prevState, searchText: e }));
        }}
        onClear={() => {
          inputRef.current.value = "";
          handleOnSearch();
        }}
      />

      <CCTextField
        value={value.status}
        label={"Registration Status"}
        select
        variant="outlined"
        margin="dense"
        className={classes.selection}
        onChange={e => setValue({ ...value, status: e.target.value })}
      >
        {customerStatusList.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </CCTextField>

      <CCTextField
        value={value.accountType}
        label={translate(intl, "common.ui.accountType")}
        select
        variant="outlined"
        margin="dense"
        className={classes.selection}
        onChange={e => setValue({ ...value, accountType: e.target.value })}
      >
        {accountTypeList.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </CCTextField>

      <CCButton
        variant="contained"
        color="secondary"
        startIcon={<SearchIcon />}
        className={classes.ml8}
        onClick={() => {
          onSearch && onSearch({ ...value });
        }}
      >
        Search
      </CCButton>
    </>
  );
};

export default RegistrationFilter;
