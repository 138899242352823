import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "components";
import { CCDialogSimple, CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialogContent-root": {
      width: 375,
      padding: "20px !important"
    }
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "normal"
  },
  subtitle: {
    marginTop: 12,
    color: theme.palette.text.secondary,
    fontSize: 14
  },
  text: { marginTop: 8, width: 280 },
  disagree: { marginLeft: 12, width: 100 },
  icon: {
    color: theme.palette.error.main,
    marginRight: 8
  },
  dialogActions: {
    padding: "0 24px 20px"
  }
}));

const DialogWarningConfirm = props => {
  const {
    open,
    onClose,
    title,
    subTitle,
    agreeButtonColor,
    agreeButtonLabel,
    onAgree,
    secondaryAction,
    secondaryActionLabel,
    disagreeButtonLabel
  } = props;
  const classes = useStyles();

  return (
    <CCDialogSimple
      open={open}
      isHeader={false}
      onClose={onClose}
      onCloseButton={onClose}
      classes={{ root: classes.root, actions: classes.dialogActions }}
      contents={
        <Box>
          {/* <WarningIcon className={classes.icon} /> */}
          <Box>
            <CCTypography className={classes.title}>
              {title ? title : "입력된 값이 올바르지 않습니다."}
            </CCTypography>
            {subTitle && (
              <CCTypography className={classes.subtitle}>
                {subTitle}
              </CCTypography>
            )}
          </Box>
        </Box>
      }
      endActions={
        <>
          {secondaryAction && (
            <Button
              className={classes.disagree}
              color={"secondary"}
              variant={"outlined"}
              onClick={() => {
                secondaryAction && secondaryAction();
              }}
            >
              {secondaryActionLabel ? secondaryActionLabel : "확인"}
            </Button>
          )}
          {disagreeButtonLabel && (
            <Button
              className={classes.disagree}
              variant="outlined"
              onClick={() => {
                onClose && onClose();
              }}
            >
              {disagreeButtonLabel ? disagreeButtonLabel : "취소"}
            </Button>
          )}
          <Button
            className={classes.disagree}
            color={agreeButtonColor ? agreeButtonColor : "warning"}
            onClick={() => {
              onAgree && onAgree();
            }}
          >
            {agreeButtonLabel ? agreeButtonLabel : "확인"}
          </Button>
        </>
      }
    />
  );
};
export default DialogWarningConfirm;
