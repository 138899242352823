import { useMutation, useQuery } from "@apollo/client";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Dialog, translate } from "components";
import { useSnackbar } from "notistack";
import { GET_NOTICES, UPDATE_NOTICE_ORDER } from "queries/notification";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useIntl } from "react-intl";
import { CCButton, CCIconButton, CCTypography } from "styles/components";
import RearrangeIcon from "theme/icons/Rearrange.icon";
import { NOTICE_TYPES } from "types/notice";
import { sortArrayByKey } from "utils/array";
import { reorderArray } from "utils/utils";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper": {
      width: props => props.width
    }
  },
  table__header: {
    display: "flex",
    height: 28,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.border.main}`
  },
  content: {
    padding: 20
  },
  noticeList: {
    minHeight: 200
  },
  noticeItem: {
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    cursor: "pointer"
  },
  noticePagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 4
  },
  noData: {
    height: 240,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  row__dragging: {
    backgroundColor: theme.palette.select.light
  }
}));

const LIMIT_PAGE = 5;

const DialogPopupOrderSetting = ({ open, onClose }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [popups, setPopups] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useQuery(GET_NOTICES, {
    variables: {
      input: {
        limit: LIMIT_PAGE,
        isPublished: true,
        page: 1,
        type: NOTICE_TYPES.POPUP
      }
    },
    onCompleted: data => {
      const popups = sortArrayByKey({
        array: data?.notices?.items || [],
        sortKey: "order",
        isSortKeyString: false
      });
      setPopups(popups);
    }
  });

  const [updateNoticeOrder] = useMutation(UPDATE_NOTICE_ORDER, {
    onCompleted: () => {
      enqueueSnackbar(translate(intl, "message.success.save"), {
        variant: "success"
      });
      onClose();
    },
    refetchQueries: [
      {
        query: GET_NOTICES,
        variables: {
          input: {
            limit: LIMIT_PAGE,
            type: NOTICE_TYPES.POPUP
          }
        }
      }
    ]
  });

  const handleDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const mPopups = reorderArray(
      popups,
      result.source.index,
      result.destination.index
    );

    setPopups(mPopups);
  };

  const handleUpdatePopup = () => {
    const mPopups = popups.map((itm, idx) => ({ id: itm.id, order: idx + 1 }));
    updateNoticeOrder({
      variables: {
        input: mPopups
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={translate(intl, "orderSetting")}
        contents={
          <Box className={classes.content}>
            <Box className={classes.table__header}>
              <Box display="flex" alignItems="center" px="8px" width="10%">
                <CCTypography variant="body2" color="textSecondary">
                  {translate(intl, "order")}
                </CCTypography>
              </Box>
              <Box display="flex" alignItems="center" px="8px" width="85%">
                <CCTypography variant="body2" color="textSecondary">
                  {translate(intl, "publishedPopups")}
                </CCTypography>
              </Box>
              <Box display="flex" alignItems="center" px="8px" width="5%"></Box>
            </Box>
            {!loading && popups.length > 0 && (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <Box
                      aria-label="notice-list"
                      className={classes.noticeList}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {popups.map((itm, idx) => (
                        <Draggable
                          key={itm.id}
                          draggableId={itm.id}
                          index={idx}
                        >
                          {(provided, snapshot) => (
                            <Box
                              aria-label="notice-item"
                              className={clsx({
                                [classes.noticeItem]: true,
                                [classes.row__dragging]: snapshot.isDragging
                              })}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Box px="7px" width="10%">
                                <CCTypography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {idx + 1}
                                </CCTypography>
                              </Box>
                              <Box px="7px" width="85%">
                                <CCTypography
                                  variant="h6"
                                  style={{ wordBreak: "break-all" }}
                                >
                                  {itm.title}
                                </CCTypography>
                              </Box>
                              <Box px="7px" width="5%">
                                <CCIconButton
                                  size="small"
                                  {...provided.dragHandleProps}
                                >
                                  <RearrangeIcon />
                                </CCIconButton>
                              </Box>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            {!loading && popups.length === 0 && (
              <Box className={classes.noData}>
                <img src="/images/noData/Notoce_none.png" alt="noData" />
                <CCTypography
                  variant="body1"
                  display="block"
                  style={{ color: "#b0bec5", fontWeight: "bold" }}
                >
                  {translate(intl, "noPopup")}
                </CCTypography>
              </Box>
            )}
            {loading && <Box className={classes.noData}></Box>}
          </Box>
        }
        contentsPadding={false}
        maxWidth={"md"}
        fullWidth
        endActions={
          <>
            <CCButton color="sub" onClick={onClose}>
              {translate(intl, "button.close")}
            </CCButton>
            <CCButton variant="contained" onClick={handleUpdatePopup}>
              {translate(intl, "button.save")}
            </CCButton>
          </>
        }
      />
    </>
  );
};

export default DialogPopupOrderSetting;
