const CalendarLeftIcon = props => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m5 8.5 5.25-5.196v10.392L5 8.5z"
      fill="currentColor"
    />
  </svg>
);

export default CalendarLeftIcon;
