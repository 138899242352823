import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { TimerWithRefetch, translate } from "components";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CCTabPanel, CCTabs } from "styles/components";
import { TabNotice, TabPopup } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: {
    height: "100%",
    width: "100%"
  }
}));

const NotificationManagement = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [triggerReset, setTriggerReset] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabOptions = [
    translate(intl, "noticeTab.notice"),
    translate(intl, "noticeTab.popup")
  ];

  return (
    <>
      <Box className={classes.timer}>
        <TimerWithRefetch refetch={() => setTriggerReset(prev => prev + 1)} />
        <div className={classes.timer__divider}></div>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCTabs
            selectedTab={selectedTab}
            tabList={tabOptions}
            onChangeTab={setSelectedTab}
          />
          <CCTabPanel value={selectedTab} index={0}>
            <TabNotice reset={triggerReset} />
          </CCTabPanel>
          <CCTabPanel value={selectedTab} index={1}>
            <TabPopup reset={triggerReset} />
          </CCTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default NotificationManagement;
