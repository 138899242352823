import { CKEditor } from "@ckeditor/ckeditor5-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Editor from "ckeditor5-custom-build";
import { Tooltip, confirm, translate } from "components";
import CVDualFlattenDateField from "components/CVDualFlattenDateField";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { CCTextField, CCTooltip, CCTypography } from "styles/components";
import { InfoIcon } from "styles/icons";
import { theme } from "theme";
import { ENTRY_LIMIT } from "types/constants";
import editorConfiguration from "utils/ckeditor";
import { popupSchema } from "utils/validations/notice";

const useStyles = makeStyles(() => ({
  root: {
    padding: 12
  },
  date__field: {
    width: 220
  },
  link__field: {
    "& textarea": {
      lineHeight: 1.5,
      fontSize: "0.875rem"
    }
  }
}));

const isDateSmallerThanToday = date => {
  return moment().startOf("day").unix() > date.startOf("day").unix();
};

const PopupForm = (props, ref) => {
  const intl = useIntl();
  const classes = useStyles();
  const { data, onSave } = props;

  const {
    control,
    formState: { isDirty },
    getValues,
    watch,
    setValue,
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(popupSchema(intl)),
    defaultValues: {
      title: "",
      content: "",
      isPublished: true,
      publishedTime: {
        publishStart: null,
        publishEnd: null
      }
    }
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        getFormDirty: () => isDirty
      };
    },
    [isDirty]
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    reset(data);
  }, [data]);

  const onSubmit = data => {
    const {
      publishedTime: { publishStart, publishEnd },
      ...rest
    } = data;
    onSave({ ...rest, publishStart, publishEnd });
  };

  return (
    <form
      id="popup-form"
      onSubmit={handleSubmit(onSubmit, errors => {
        if (
          Boolean(
            errors?.publishedTime?.publishEnd?.type === "validePublishEnd"
          )
        ) {
          confirm({
            type: "warning",
            title: translate(intl, "message.error.PUBLISH_END"),
            okTitle: translate(intl, "button.ok"),
            onConfirm: () => {}
          });
        } else if (Boolean(errors?.publishedTime)) {
          confirm({
            type: "warning",
            title: translate(intl, "message.error.PUBLISH_DATE"),
            okTitle: translate(intl, "button.ok"),
            onConfirm: () => {}
          });
        }
      })}
    >
      <Grid container direction="column" className={classes.root}>
        <Box display="flex" alignItems="center">
          <Box width={200}>
            <Controller
              name="isPublished"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <Tooltip
                  intent="error"
                  title={error?.message}
                  open={Boolean(error)}
                >
                  <CCTextField
                    select
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    label={
                      <span>
                        {translate(intl, "publish")}{" "}
                        <span className="asterisk">*</span>
                      </span>
                    }
                    value={value}
                    onChange={e => {
                      if (e.target.value) {
                        // check if publish end is smaller than today, reset date field
                        const publishEnd =
                          getValues("publishedTime").publishEnd;

                        if (isDateSmallerThanToday(moment.unix(publishEnd))) {
                          setValue("publishedTime", {
                            publishStart: null,
                            publishEnd: null
                          });
                        }
                      } else {
                        // check if unpublish, reset date field
                        setValue("publishedTime", {
                          publishStart: null,
                          publishEnd: null
                        });
                      }
                      onChange(e);
                    }}
                  >
                    <MenuItem key="true" value={true}>
                      {translate(intl, "publish")}
                    </MenuItem>
                    <MenuItem key="false" value={false}>
                      {translate(intl, "unpublish")}
                    </MenuItem>
                  </CCTextField>
                </Tooltip>
              )}
            />
          </Box>
          <CCTooltip
            title={
              <CCTypography
                color="inherit"
                variant="body1"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {translate(intl, "message.error.NOTICE03")}
              </CCTypography>
            }
          >
            <Box>
              <InfoIcon style={{ color: theme.palette.text.label }} />
            </Box>
          </CCTooltip>
          <Box ml="8px">
            <Controller
              name="publishedTime"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => (
                <CVDualFlattenDateField
                  className={classes.date__field}
                  labelLeft={translate(intl, "publishStart")}
                  labelRight={translate(intl, "publishEnd")}
                  valueLeft={
                    value?.publishStart
                      ? moment(value?.publishStart, "X")
                      : false
                  }
                  valueRight={
                    value?.publishEnd ? moment(value?.publishEnd, "X") : false
                  }
                  onChangeLeft={e => {
                    onChange({
                      ...value,
                      publishStart: moment(e).unix()
                    });
                  }}
                  onChangeRight={e => {
                    onChange({
                      ...value,
                      publishEnd: moment(e).unix()
                    });
                  }}
                  disabled={!watch("isPublished")}
                />
              )}
            />
          </Box>
        </Box>
        <Controller
          name="title"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Tooltip
              intent="error"
              title={error?.message}
              open={Boolean(error)}
            >
              <CCTextField
                variant="outlined"
                margin="dense"
                fullWidth
                error={error}
                label={
                  <span>
                    {translate(intl, "title")}{" "}
                    <span className="asterisk">*</span>
                  </span>
                }
                inputProps={{
                  maxLength: ENTRY_LIMIT.TEXT_FIELD.MIDDLE
                }}
                {...field}
                onBlur={e => field.onChange(e.target.value.trim())}
              />
            </Tooltip>
          )}
        />
        <Box m="8px" className="ck-content">
          <Controller
            name="content"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CKEditor
                editor={Editor}
                config={editorConfiguration}
                data={value}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  onChange(data);
                }}
              />
            )}
          />
        </Box>
      </Grid>
    </form>
  );
};

export default forwardRef(PopupForm);
