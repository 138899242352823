import palette from "../default/palette";

export default {
  root: {
    margin: 8
  },
  input: {
    "&$disabled": {
      backgroundColor: palette.inactive.main
    }
  }
};
