import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVTable, Image, Switch, translate } from "components";
import moment from "moment";
import { GET_NUMBER_USER_LAB } from "queries/customer";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CCButton } from "styles/components";
import { makeList } from "types";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from "types/constants";
import { ACCOUNT_TYPE_VALUES } from "types/registrationManage";
import {
  BASE_USER_STATUS,
  DETAIL_REFERENCE_DATA,
  USER_STATUS_TYPES
} from "types/userManage";
import { mergeArrayByKey } from "utils/array";
import { LocalizeVariable } from "utils/env";

const useStyles = makeStyles(theme => ({
  root: {
    // display: "flex",
    // flexDirection: "row",
    // height: "100%",
    minWidth: "500"

    // overflow: "hidden",
    // backgroundColor: theme.palette.common.white
  },
  table__cell: {
    padding: "6px 4px",
    width: "500px",
    backgroundColor: "white"
    // borderBottom: "0.2px solid #e7e9eb"
  },
  table__body: {
    position: "relative",
    minHeight: 40,
    overflow: "auto"
    // "& > div:nth-child(odd)": {
    //   background: theme.palette.background.default
    // },
  },
  cancel__button: {
    position: "absolute",
    top: "8px",
    right: "16px"
  },
  status__selection: {
    minWidth: "200px"
  },
  approved__field: {
    color: "#00897b"
  },
  changeStatus__btn: {
    minWidth: "185px",
    border: "0.1px ridge rgb(38, 50, 56)",
    height: "20",
    "border-radius": "4px"
    // "border-color": ,
  },
  status__text: {
    // width: "150px",
    "text-transform": "none",
    textAlign: "center"
  },
  in_use_status: {
    color: "#00897b"
  },
  temporary_status: {
    color: "#f50057"
  },
  registration__field: {
    padding: "5px 0",
    borderBottom: "1px solid #e7e9eb"
  }
}));

const reformatDetailLabData = userInfo => {
  // userInfo.birthDate = moment.unix(labInformation.createdAt).format("L");
  const arr2 = [];
  userInfo.birthDate = moment
    .unix(userInfo.birthDate)
    .format(DEFAULT_DATE_FORMAT);
  for (const key in userInfo) {
    arr2.push({
      key,
      value: userInfo[key]
    });
  }
  const resultMerged = mergeArrayByKey(DETAIL_REFERENCE_DATA, arr2, "key");

  // console.log("resultMerged", resultMerged)
  return resultMerged;
};

const UserDetail = props => {
  const { data, onUpdate, onChangeAccountType } = props;

  // console.log("UserDetail.data", data);
  const currentStatus = String(data.currentStatus);
  let { status, statusUpdatedAt } = data;
  status = String(status);

  const [anchorEl, setAnchorEl] = useState(null);
  const [newStatus, setNewStatus] = useState(data.status);
  const intl = useIntl();

  // console.log("currentStatus", currentStatus, "status", status);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    // console.log("handleClose", e.target.value)
    // if(e.target.value) {
    // onUpdate({ ...data, status: e.target.value});
    // }
    setAnchorEl(null);
  };

  const classes = useStyles();
  const { IN_USE, DISCONTINUED, WITH_DRAW } = BASE_USER_STATUS;
  let userStatusList = makeList(USER_STATUS_TYPES);
  userStatusList =
    currentStatus !== WITH_DRAW
      ? userStatusList.filter(
          o => o.value === IN_USE || o.value === DISCONTINUED
        )
      : userStatusList.filter(o => o.value === WITH_DRAW);

  const [open, setIsOpen] = useState(true);
  // const [status, setStatus] = useState();

  const { data: numberUserResponse, refetch: refetchStatistics } = useQuery(
    GET_NUMBER_USER_LAB,
    {
      variables: {
        labId: data.labId || ""
      },
      onCompleted: response => {
        // setContents(reformatDetailLabData({ ... data, numberUserAccount:  response?.getNumberUserOfLab}));
      }
    }
  );

  const contents = reformatDetailLabData({
    ...data,
    staffId: data?.staffId,
    address: LocalizeVariable.isKorean
      ? `${data?.address?.address || ""} - ${
          data?.address?.detailAddress || ""
        }`
      : data?.address?.address || "",
    birthDate: data?.foundingDate,
    referral: data?.referral,
    numberUserAccount: numberUserResponse?.getNumberUserOfLab
  });

  const heads = [
    {
      key: "key",
      className: classes.table__cell,

      component: ({ rowData }) => {
        return <b>{rowData.label}</b>;
      }
    },
    {
      key: "value",
      // label: "Lab Name",
      className: classes.table__cell,
      component: ({ rowData, ...other }) => {
        if (rowData.key === "registrationStatus") {
          // ({acceptedDate ? moment(acceptedDate).format(DEFAULT_DATE_TIME_FORMAT)})
          return (
            <Grid>
              <b className={classes.approved__field}>
                {rowData.value || "Approved"}
              </b>{" "}
              (
              {moment
                .unix(data.registrationAt)
                .format(DEFAULT_DATE_TIME_FORMAT)}
              )
            </Grid>
          );
        }

        if (rowData.key === "numberUserAccount") {
          return rowData.value || 0;
        }

        // if (rowData.key === "numberUserAccount") {
        //   return rowData.value || 0;
        // }

        if (rowData.key === "businessRegistration") {
          if (rowData?.value?.url) return <Image data={rowData.value} />;
          return <div />;
        }

        return rowData.value;
      }
    }
  ];

  const handleChangeStatus = e => {
    onUpdate({ ...data, status: e.target.value });
    setAnchorEl(null);
  };

  console.log(11, { heads, contents });

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <CVTable
          classes={classes}
          heads={heads}
          contents={contents}
          del={({ rowIndex }) => {}}
          mod={() => {
            console.log("mod");
          }}
          add={() => {
            console.log("add");
          }}
          sorting={true}
        />
      </Grid>

      <Grid container className={classes.registration__field}>
        <Grid item xs={6}>
          <b>Usage Status</b>
        </Grid>
        <Grid item container justify="flex-start" alignItems="center" xs={6}>
          <p
            className={
              currentStatus === IN_USE
                ? classes.in_use_status
                : classes.temporary_status
            }
          >
            {currentStatus === IN_USE
              ? translate(intl, "user.status.inUse")
              : translate(intl, "user.status.temporarySuspended")}
          </p>
          &nbsp; (
          {moment.unix(statusUpdatedAt).format(DEFAULT_DATE_TIME_FORMAT)})
          &nbsp;
          <CCButton
            variant={"contained"}
            className={
              currentStatus === IN_USE
                ? classes.temporary_status
                : classes.in_use_status
            }
            size={"small"}
            color={"normal"}
            onClick={() =>
              onUpdate({
                ...data,
                status: currentStatus == IN_USE ? DISCONTINUED : IN_USE
              })
            }
            // onClick={onUpdate({ ...data, status: PROCESSING_TERMINATED })}
          >
            {currentStatus === IN_USE
              ? translate(intl, "user.status.temporarySuspended")
              : translate(intl, "user.status.inUse")}
          </CCButton>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        className={classes.registration__field}
      >
        <Grid item xs={6}>
          <Typography variant="h5">
            {translate(intl, "common.ui.accountType")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch
            checked={data.accountType === ACCOUNT_TYPE_VALUES.TEST.value}
            labelTextOn={translate(intl, "accountType.test")}
            labelTextOff={translate(intl, "accountType.lab")}
            onChange={event => {
              onChangeAccountType({
                ...data,
                accountType: event.target.checked
                  ? ACCOUNT_TYPE_VALUES.TEST.value
                  : ACCOUNT_TYPE_VALUES.LAB.value
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDetail;
