import { Box } from "@material-ui/core";
import { Modal, confirm, translate } from "components";
import { useRef } from "react";
import { useIntl } from "react-intl";
import { CCButton } from "styles/components";
import { CheckIcon } from "styles/icons";
import NotificationForm from "./NotificationForm";

const DialogNotificationForm = props => {
  const intl = useIntl();
  const ref = useRef(null);
  const { open, selectedNotice, onClose, onSave } = props;

  const handleCancelDialog = () => {
    if (ref.current.getFormDirty()) {
      return confirm({
        type: "warning",
        title: translate(intl, "dialog.askCancel"),
        subTitle: translate(intl, "dialog.cancelWarning"),
        okTitle: translate(intl, "button.leave"),
        cancelTitle: translate(intl, "button.stay"),
        onConfirm: () => {
          onClose();
        }
      });
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCancelDialog}
      width={960}
      title={translate(intl, "noticeTab.notice")}
      body={
        <NotificationForm data={selectedNotice} onSave={onSave} ref={ref} />
      }
      footer={
        <Box display="flex" justifyContent="end">
          <CCButton color="sub" onClick={handleCancelDialog}>
            {translate(intl, "button.cancel")}
          </CCButton>
          <CCButton
            type="submit"
            form="notice-form"
            startIcon={<CheckIcon />}
            variant="contained"
          >
            {translate(intl, "button.save")}
          </CCButton>
        </Box>
      }
    />
  );
};

export default DialogNotificationForm;
