import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";

export const CreateLabApplicationRequestDocument = gql`
  mutation createLabApplicationRequest($input: CreateLabRequestInput!) {
    createLabApplicationRequest(input: $input) {
      id
    }
  }
`;

// cellNo
// labBusinessRegistrationNumber
// labId
// labManagerName
// labName
// labTel
// password
// staffId

export function useCreateLabApplicationRequestMutation(baseOptions) {
  return Apollo.useMutation(CreateLabApplicationRequestDocument, baseOptions);
}
