import { makeStyles } from "@material-ui/styles";
import { Autocomplete, GoogleMap, Marker } from "@react-google-maps/api";
import produce from "immer";
import propTypes from "prop-types";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useIntl } from "../../../../../locales/language";
import {
  CCButton,
  CCDialog,
  CCDialogActions,
  CCDialogContent,
  CCDialogTitle,
  CCTextField
} from "../../../../components";
import { useLoadScriptWithLocales } from "../../utils";

const useStyle = makeStyles(theme => ({
  root: {},
  contents: {
    "body .pac-container": {
      zIndex: 9000
    }
  }
}));

const initializer = props => {
  const { value } = props;
  return {
    isLoaded: false,
    value: {
      address: value?.address ? value.address : "",
      coordinate:
        Boolean(value?.coordinate?.latitude) &&
        Boolean(value?.coordinate?.longitude)
          ? {
              longitude: value.coordinate.latitude,
              latitude: value.coordinate.longitude
            }
          : null,
      detailAddress: value?.detailAddress ? value.detailAddress : "",
      placeId: value?.placeId ? value.placeId : ""
    }
  };
};
const reducer = produce((draft, action) => {
  switch (action.type) {
    case "setLoad":
      draft.isLoaded = action.target;
      break;
    case "setValue":
      draft.value = action.target;
      break;
    default:
      console.error(`unexpected action type : ${action?.type}`);
      break;
  }
});

const CCAddressSearchDialog = props => {
  const { value, open, onClose, apiKey, onChange } = props;
  const intl = useIntl();
  const { isLoaded } = useLoadScriptWithLocales({ apiKey: apiKey });
  const [state, dispatchState] = useReducer(reducer, props, initializer);
  const classes = useStyle();
  const place = useRef();
  const map = useRef();
  const [zoom, setZoom] = useState(17);
  const inputRef = useRef();
  useEffect(() => {
    dispatchState({ type: "setLoad", target: isLoaded });
  }, [isLoaded]);
  useEffect(() => {
    dispatchState({ type: "setValue", target: value });
  }, [value]);
  return (
    <CCDialog open={open} size={"md"}>
      <CCDialogTitle onCloseButton={onClose}>
        {intl.formatMessage({ id: "address" })}
      </CCDialogTitle>
      <CCDialogContent className={classes.contents}>
        {state.isLoaded && (
          <>
            <Autocomplete
              onPlaceChanged={e => {
                if (
                  place?.current?.getPlace &&
                  place?.current?.getPlace().geometry?.location
                ) {
                  let _place = place.current.getPlace();
                  let coords = {
                    latitude: _place.geometry.location.lat(),
                    longitude: _place.geometry.location.lng()
                  };
                  setZoom(17);
                  map.current.panTo({
                    lat: coords.latitude,
                    lng: coords.longitude
                  });
                  dispatchState({
                    type: "setValue",
                    target: {
                      address: _place?.formatted_address,
                      coordinate: coords,
                      placeId: _place?.place_id,
                      detailAddress: state?.value?.detailAddress
                    }
                  });
                }
              }}
              onLoad={e => {
                place.current = e;
              }}
            >
              <CCTextField
                inputRef={inputRef}
                inputProps={{
                  defaultValue: state?.value?.address || ""
                }}
                label={intl.formatMessage({ id: "address" })}
                placeholder={intl.formatMessage({ id: "address" })}
                margin={"dense"}
                variant={"outlined"}
                style={{ width: 500 }}
              />
            </Autocomplete>
            <GoogleMap
              mapContainerStyle={{ width: 500, height: 500 }}
              center={
                Boolean(state?.value?.coordinate)
                  ? {
                      lat: state.value.coordinate.latitude,
                      lng: state.value.coordinate.longitude
                    }
                  : {
                      lat: 59.95,
                      lng: 30.33
                    }
              }
              zoom={zoom}
              onLoad={_map => {
                map.current = _map;
              }}
              options={{
                disableDefaultUI: false,
                draggable: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
                streetViewControl: false,
                mapTypeControl: false
              }}
            >
              {Boolean(state?.value?.coordinate) && (
                <Marker
                  position={{
                    lat: state.value.coordinate.latitude,
                    lng: state.value.coordinate.longitude
                  }}
                />
              )}
            </GoogleMap>
          </>
        )}
      </CCDialogContent>
      <CCDialogActions>
        <CCButton
          variant={"text"}
          onClick={() => {
            dispatchState({ type: "setValue", target: value });
            onClose && onClose();
          }}
        >
          {intl.formatMessage({ id: "cancel" })}
        </CCButton>
        <CCButton
          variant={"contained"}
          onClick={() => {
            onChange && onChange(JSON.parse(JSON.stringify(state.value)));
            onClose && onClose();
          }}
        >
          {intl.formatMessage({ id: "check" })}
        </CCButton>
      </CCDialogActions>
    </CCDialog>
  );
};

CCAddressSearchDialog.propTypes = {
  value: propTypes.shape({
    address: propTypes.string,
    coordinate: propTypes.shape({
      latitude: propTypes.number,
      longitude: propTypes.number
    }),
    placeId: propTypes.string
  }),
  apiKey: propTypes.string.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func
};

export default CCAddressSearchDialog;
