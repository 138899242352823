import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { CCButton, CCIconButton, CCTooltip, CCTypography } from "../";
import { SearchIcon, SettingIcon } from "../../themes/common/icons";

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.result.main,
    position: "relative",
    padding: 8,
    marginRight: "16px"
  },
  btn__root: {
    display: "inline-flex",
    alignItems: "center",
    margin: "4px 0 0 8px"
  },
  btn__group: {
    display: "inline-block",
    "& button:first-child": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px"
    },
    "& button:last-child": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px"
    }
  },
  title: {
    // marginBottom: 7,
    marginRight: 8
    // display: "inline-block",
  },
  title__btn: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    background: "none",
    border: "none",
    padding: 2,
    // marginBottom: 4,
    marginRight: 7,
    "&:focus": {
      background: "lightskyblue"
    }
  },
  btn: {
    margin: "0",
    borderRadius: "0",
    marginBottom: 0,
    height: 28,
    minWidth: 0
  },
  btn__none__ellipsis: {
    height: "auto !important"
  },
  btn__focus: {
    "&:focus": {
      // outline: "-webkit-focus-ring-color auto 5px;",
      // outline: "#0091ff auto 5px",
      backgroundColor: theme.palette.focus,
      border: `1px solid ${theme.palette.focusBorder}`,
      zIndex: 1
    }
  },
  setting__icon: {
    top: "-4px",
    right: "4px",
    position: "absolute"
  },
  search__icon: {
    top: "2px",
    right: "40px",
    position: "absolute"
  },
  root__padding: {
    padding: "4px 30px 8px 0"
  },
  btn__ellipsis: {
    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  ellipsis: {
    "& .MuiButton-label": {
      display: "block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  },
  inline__block: {
    display: "inline-block"
  }
}));

const CCTitleButtonList = forwardRef((props, ref) => {
  const {
    btnGroupList,
    selectedBtn,
    btnClick,
    settingClick,
    settingTooltip,
    searchClick,
    searchTooltip = "",
    onBlur = () => {},
    tooltip = false,
    tooltipComponent,
    maxWidths = null,
    classes: userClasses,
    noneEllipsis = false,
    onTooltip = () => {
      return "";
    }
  } = props;
  const classes = useStyles(props);
  const iconRefs = useRef({});
  const Tooltip = tooltipComponent || CCTooltip;

  const btnClickFunc = e => {
    btnClick(e);
  };

  useImperativeHandle(ref, () => ({
    getButtonsRef: () => {
      return iconRefs;
    }
  }));

  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.root__padding]: settingClick
      })}
    >
      {btnGroupList.map((group, index) => (
        <div className={classes.btn__root} key={index}>
          {tooltip ? (
            <Tooltip title={group.name}>
              <div className={classes.inline__block}>
                <button
                  className={clsx(classes.title__btn)}
                  style={{
                    width: group?.width,
                    maxWidth: maxWidths?.title || "auto"
                  }}
                  ref={ref => {
                    iconRefs.current[group.id] = ref;
                  }}
                  onBlur={onBlur}
                >
                  <CCTypography
                    className={classes.btn__ellipsis}
                    variant={"body1"}
                  >
                    {group.name}
                  </CCTypography>
                </button>
              </div>
            </Tooltip>
          ) : (
            <button
              className={clsx(classes.title__btn)}
              style={{
                width: group?.width,
                maxWidth: maxWidths?.title || "auto"
              }}
              ref={ref => {
                iconRefs.current[group.id] = ref;
              }}
              onBlur={onBlur}
            >
              <CCTypography className={classes.btn__ellipsis} variant={"body1"}>
                {group.name}
              </CCTypography>
            </button>
          )}
          <div
            className={clsx(classes.btn__group, {
              [userClasses?.btn__group]: Boolean(userClasses?.btn__group)
            })}
            key={index}
          >
            {group.items &&
              group.items.map((btn, index) => {
                if (tooltip)
                  return (
                    <Tooltip
                      btn={btn}
                      title={onTooltip(btn)}
                      key={index}
                      placement="bottom"
                      arrow
                      enterNextDelay={1200}
                      enterDelay={1200}
                    >
                      <div className={classes.inline__block}>
                        <CCButton
                          noneEllipsis={noneEllipsis}
                          onBlur={onBlur}
                          id={btn.id}
                          key={index}
                          className={clsx(classes.btn, {
                            [classes.btn__focus]:
                              selectedBtn.findIndex(e => e.id === btn.id) ===
                              -1,
                            [classes.btn__none__ellipsis]: noneEllipsis
                          })}
                          onClick={() => btnClickFunc(btn)}
                          color={
                            selectedBtn.findIndex(e => e.id === btn.id) !== -1
                              ? "selected"
                              : "normal"
                          }
                          style={{
                            maxWidth: maxWidths?.btn || "auto"
                          }}
                          variant={"contained"}
                          size={"small"}
                          ref={ref => {
                            iconRefs.current[btn.id] = ref;
                          }}
                        >
                          {btn.name}
                        </CCButton>
                      </div>
                    </Tooltip>
                  );
                return (
                  <CCButton
                    noneEllipsis={noneEllipsis}
                    onBlur={onBlur}
                    id={btn.id}
                    key={index}
                    className={clsx(classes.btn, {
                      [classes.btn__focus]:
                        selectedBtn.findIndex(e => e.id === btn.id) === -1,
                      [classes.btn__none__ellipsis]: noneEllipsis
                    })}
                    onClick={() => btnClickFunc(btn)}
                    color={
                      selectedBtn.findIndex(e => e.id === btn.id) !== -1
                        ? "selected"
                        : "normal"
                    }
                    variant={"contained"}
                    style={{
                      maxWidth: maxWidths?.btn || "auto"
                    }}
                    size={"small"}
                    ref={ref => {
                      iconRefs.current[btn.id] = ref;
                    }}
                  >
                    {btn.name}
                  </CCButton>
                );
              })}
          </div>
        </div>
      ))}
      {settingClick && (
        <div className={classes.setting__icon}>
          <CCIconButton onClick={settingClick} tooltip={settingTooltip}>
            <SettingIcon />
          </CCIconButton>
        </div>
      )}
      {searchClick && (
        <div className={classes.search__icon}>
          <CCIconButton
            size={"small"}
            color={"normal"}
            variant={"contained"}
            onClick={searchClick}
            tooltip={searchTooltip}
          >
            <SearchIcon />
          </CCIconButton>
        </div>
      )}
    </Box>
  );
});

CCTitleButtonList.propTypes = {
  /** 버튼 배열 */
  btnList: PropTypes.array,
  /** 선택된 버튼 배열 */
  selectedBtn: PropTypes.array,
  /** 버튼 클릭 시 실행되는 함수 */
  btnClick: PropTypes.func,
  /** SettingIcon 클릭 시 실행되는 함수 */
  settingClick: PropTypes.func
};

export default CCTitleButtonList;
