import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import { Button } from "components";

const useStyles = makeStyles(theme => ({
  root: {
    width: 375
  },
  title: {
    fontWeight: "bold",
    fontSize: 16,
    color: theme.palette.text.primary
  },
  subTitle: {
    marginTop: 12,
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    padding: "20px 24px"
  },
  dialogActions: {
    padding: "0 24px 20px",

    "&.MuiDialogActions-spacing > :not(:first-child)": {
      marginLeft: "12px"
    }
  },
  buttonActions: {
    width: 100
  }
}));

const DialogConfirmation = props => {
  const {
    onClose,
    title,
    subTitle,
    okTitle,
    cancelTitle,
    onConfirm,
    type,
    onCancel,
    subTitle2,
    ...rest
  } = props;

  const classes = useStyles();

  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose();
  };

  return (
    <Dialog
      {...rest}
      // aria-labelledby="alert-dialog-slide-title"
      // aria-describedby="alert-dialog-slide-description"
    >
      <div className={clsx(classes.root)}>
        <DialogContent className={classes.dialogContent}>
          <div style={{ width: "100%" }}>
            <div className={clsx(classes.title)}>{title}</div>
            {subTitle && (
              <div className={clsx(classes.subTitle)}>{subTitle}</div>
            )}
            {!!subTitle2 && (
              <div className={clsx(classes.subTitle)}>{subTitle2}</div>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {cancelTitle && (
            <Button
              variant="outlined"
              className={classes.buttonActions}
              onClick={handleCancel}
            >
              {cancelTitle}
            </Button>
          )}
          {onConfirm ? (
            <Button
              color={type === "warning" ? "warning" : "primary"}
              className={classes.buttonActions}
              onClick={handleConfirm}
            >
              {okTitle}
            </Button>
          ) : null}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DialogConfirmation;
