import { DialogPermissionWarn } from "components";
import React, { useState } from "react";
import CCFlattenDateField from "../CCFlattenDateTimePicker/CCFlattenDateField";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  rootLeft: {
    marginRight: 0,
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    "& fieldset": {
      borderRight: 0
    }
  },
  rootRight: {
    marginLeft: 0,
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  }
}));

const CVDualFlattenDateField = props => {
  const classes = useStyles();
  const {
    children,
    permission,
    className: customClassName,
    valueLeft,
    valueRight,
    labelLeft,
    labelRight,
    onChangeLeft,
    onKeyDownLeft,
    onKeyPressLeft,
    onChangeRight,
    onKeyDownRight,
    onKeyPressRight,
    ...others
  } = props;
  const [openLeft, setOpenLeft] = useState(false);
  const [openRight, setOpenRight] = useState(false);

  const isPermission = true;

  const handleChangeLeft = () => {
    setOpenLeft(true);
  };

  const handleChangeRight = () => {
    setOpenRight(true);
  };

  return (
    <>
      <CCFlattenDateField
        {...others}
        className={clsx(classes.rootLeft, customClassName)}
        value={valueLeft}
        label={labelLeft}
        onChange={isPermission ? onChangeLeft : handleChangeLeft}
        onKeyDown={isPermission ? onKeyDownLeft : handleChangeLeft}
        onKeyPress={isPermission ? onKeyPressLeft : handleChangeLeft}
        onClickIconPermission={isPermission ? null : handleChangeLeft}
      >
        {children}
      </CCFlattenDateField>
      <CCFlattenDateField
        {...others}
        className={clsx(classes.rootRight, customClassName)}
        value={valueRight}
        label={labelRight}
        onChange={isPermission ? onChangeRight : handleChangeRight}
        onKeyDown={isPermission ? onKeyDownRight : handleChangeRight}
        onKeyPress={isPermission ? onKeyPressRight : handleChangeRight}
        onClickIconPermission={isPermission ? null : handleChangeRight}
      >
        {children}
      </CCFlattenDateField>
      <DialogPermissionWarn
        open={openLeft}
        onClose={() => {
          setOpenLeft(false);
        }}
      />
      <DialogPermissionWarn
        open={openRight}
        onClose={() => {
          setOpenRight(false);
        }}
      />
    </>
  );
};

export default CVDualFlattenDateField;
