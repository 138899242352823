import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import hangul from "hangul-js";
import React, {
  forwardRef,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from "react";
import { useIntl as reactUseIntl } from "react-intl";
import { v4 as uuid } from "uuid";
import {
  CCDialogSimpleAddMod as CCDialogSimpleAdd,
  CCDialogSimpleAddMod as CCDialogSimpleMod,
  CCDialogSimpleDel,
  CCIconButton,
  CCSearchMenu
} from "../";
import { useIntl } from "../../../locales/language";

import {
  AddIcon,
  ButtonIcon,
  DeleteIcon,
  FoldallIcon,
  FolderIcon,
  ModifyIcon,
  MovedownIcon,
  MoveupIcon,
  OpenfolderIcon
} from "../../themes/common/icons";

const nameSyncCategorys = [
  "prescriptionSetting",
  "radioGraphDecodeSetting",
  "smsSetting",
  "treatmentPlanSetting",
  "schoolSetting",
  "entrustedInstitutionSetting",
  "partnerSetting"
];

const extraction = text => {
  const kor = [
    "ㄱ",
    "ㄲ",
    "ㄴ",
    "ㄷ",
    "ㄸ",
    "ㄹ",
    "ㅁ",
    "ㅂ",
    "ㅃ",
    "ㅅ",
    "ㅆ",
    "ㅇ",
    "ㅈ",
    "ㅉ",
    "ㅊ",
    "ㅋ",
    "ㅌ",
    "ㅍ",
    "ㅎ"
  ];
  let result = "";

  for (let i = 0; i < text.length; i++) {
    let utf = text.charCodeAt(i) - 44032;

    if (utf > -1 && utf < 11172) {
      result += kor[Math.floor(utf / 588)];
    } else {
      result += text.charAt(i);
    }
  }
  return result;
};

const toolbarHeight = 36;
const searchHeight = 54;
const useStyles = makeStyles(theme => ({
  root: { height: "100%", borderBottom: `1px solid #ababab` },
  depth: {},
  search: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    height: searchHeight
  },
  item: {
    padding: props =>
      props.locale === "ar"
        ? `0 ${(props.depth ? props.depth : 0 + 0) * 20 + 10}px 0 8px`
        : `0 8px 0 ${(props.depth ? props.depth : 0 + 0) * 20 + 10}px`,
    cursor: "pointer",
    height: 36,
    whiteSpace: "nowrap"
  },
  selected: { backgroundColor: theme.palette.select.main },
  hidden: { display: "none" },
  fold__icon: { height: 24, color: theme.palette.icon, margin: 0 },
  icon__btn: { margin: 0 },
  icon__delete: { color: theme.palette.error.main },
  toolbar: {
    height: toolbarHeight,
    justifyContent: "space-between",
    padding: "0 8px",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  tree: {
    height: props => {
      if (Boolean(props?.disabledToolbar) && Boolean(props?.disabledSearch)) {
        return "100%";
      } else if (Boolean(props?.disabledToolbar)) {
        return `calc(100% - ${searchHeight + 2}px)`;
      } else if (Boolean(props?.disabledToolbar)) {
        return `calc(100% - ${toolbarHeight}px)`;
      } else {
        return `calc(100% - ${toolbarHeight}px - ${searchHeight}px + 2px)`;
      }
    },
    overflow: "auto"
  },
  tree__inner: {
    minWidth: "fit-content",
    width: "100%"
  }
}));

const updateItem = (items, selected, item, depth = 0, noItem = false) => {
  const newItems = [...items];

  if (!selected.length) {
    return;
  } else if (selected.length - 1 === depth) {
    return newItems.map((el, index) => {
      if (index === selected[depth])
        return noItem ? { ...el, ...item } : { ...el, item: item };
      return el;
    });
  } else {
    const newItem = {
      ...newItems[selected[depth]],
      items: updateItem(
        newItems[selected[depth]].items,
        selected,
        item,
        depth + 1,
        noItem
      )
    };
    newItems[selected[depth]] = newItem;
  }

  return newItems;
};

const getItems = (items, selected, depth = 0) => {
  if (!selected.length) {
    return null;
  } else if (selected.length - 1 === depth) {
    return items[selected[depth]];
  }
  if (items[selected[depth]]?.items) {
    return getItems(items[selected[depth]].items, selected, depth + 1);
  } else {
    return items[selected[depth]];
  }
};

const Item = forwardRef((props, ref) => {
  const {
    onClick,
    item,
    depth,
    totalDepth,
    selected,
    selectedCmd,
    history
  } = props;
  const intl = reactUseIntl();
  const classes = useStyles({ ...props, depth, locale: intl?.locale });
  const state = item.state;

  return (
    <div>
      <div
        className={clsx({
          [classes.item]: true,
          [classes.selected]: history.join(",") === selected.join(",")
        })}
        style={{ display: "flex", alignItems: "center" }}
        onClick={e => {
          onClick(history, totalDepth === depth ? "item" : "dir", item);
        }}
      >
        <CCIconButton
          className={classes.fold__icon}
          ref={btnRef => {
            if (history.join(",") === selectedCmd.join(",")) {
              ref.current = btnRef;
            }
          }}
          onClick={e => {
            // e.stopPropagation();
            // if (totalDepth === depth) onClick(history, "dir", item);
            // if (totalDepth !== depth) onClick(history, "icon", item);
          }}
        >
          {totalDepth === depth ? (
            <ButtonIcon />
          ) : state ? (
            <OpenfolderIcon />
          ) : (
            <FolderIcon />
          )}
        </CCIconButton>
        <div>{item.name}</div>
      </div>
      {totalDepth !== depth && state && (
        <Items
          className={clsx({ [classes.hidden]: !state })}
          items={item.items}
          depth={depth + 1}
          history={history}
          onClick={onClick}
          selected={selected}
          selectedCmd={selectedCmd}
          totalDepth={totalDepth}
          ref={ref}
        />
      )}
    </div>
  );
});

const Items = forwardRef((props, ref) => {
  const {
    className,
    items,
    onClick,
    selected,
    selectedCmd,
    totalDepth,
    depth = 1,
    history = []
  } = props;

  return (
    <div className={className}>
      {items.map((item, index) => {
        const newHistory = history.concat(index);
        return (
          <Item
            key={index}
            onClick={onClick}
            item={item}
            depth={depth}
            totalDepth={totalDepth}
            selected={selected}
            selectedCmd={selectedCmd}
            history={newHistory}
            ref={ref}
          />
        );
      })}
    </div>
  );
});

const changeStateTo = (items, history, state, depth = 0) => {
  const newItems = [...items];

  if (!history.length) {
    return;
  } else if (history.length - 1 === depth) {
    const newItem = {
      ...newItems[history[depth]],
      state: state
    };
    newItems[history[depth]] = newItem;
  } else {
    const newItem = {
      ...newItems[history[depth]],
      items: changeState(newItems[history[depth]].items, history, depth + 1)
    };
    newItems[history[depth]] = newItem;
  }

  return newItems;
};

const changeStateAll = (data, state) => {
  return data.map(el => {
    const newEl = { ...el };

    if (newEl.items) {
      newEl.state = state;
      newEl.items = changeStateAll(newEl.items, state);
    }

    return newEl;
  });
};

const changeState = (items, history, depth = 0) => {
  const newItems = [...items];

  if (!history.length) {
    return;
  } else if (history.length - 1 === depth) {
    const newItem = {
      ...newItems[history[depth]],
      state: !newItems[history[depth]].state
    };
    newItems[history[depth]] = newItem;
  } else {
    const newItem = {
      ...newItems[history[depth]],
      items: changeState(newItems[history[depth]].items, history, depth + 1)
    };
    newItems[history[depth]] = newItem;
  }

  return newItems;
};

const openFolder = (items, history, depth = 0) => {
  const newItems = [...items];

  if (!history.length) {
    return;
  } else if (history.length - 1 === depth) {
    const newItem = {
      ...newItems[history[depth]]
    };
    newItems[history[depth]] = newItem;
  } else {
    const newItem = {
      ...newItems[history[depth]],
      state: true,
      items: openFolder(newItems[history[depth]].items, history, depth + 1)
    };
    newItems[history[depth]] = newItem;
  }

  return newItems;
};

const deleteTreeState = trees => {
  let _trees = JSON.parse(JSON.stringify(trees));
  _trees.forEach(item => {
    if (item.hasOwnProperty("state")) {
      item.state = false;
    }
    if (item.hasOwnProperty("items")) {
      item.items = deleteTreeState(item.items);
    }
  });
  return _trees;
};
const findHistory = (trees, id, type) => {
  for (let i in trees) {
    if (trees[i].id === id) {
      if (type) {
        if (trees[i].item.type === type) {
          return [Number(i)];
        }
      } else {
        return [Number(i)];
      }
    }
    if (trees[i]?.items) {
      let _res = findHistory(trees[i].items, id);
      if (_res) {
        _res.unshift(Number(i));
        return _res;
      }
    }
  }
};

const initDialog = {
  add: { state: false, value: null },
  mod: { state: false, value: null },
  del: { state: false, value: null }
};

const CCTreeExp = props => {
  const {
    treeData,
    totalDepth,
    className,
    onClickItem = () => {},
    onClickDir = () => {},
    onChange = () => {},
    title,
    disabledToolbar = false,
    disabledSearch = false,
    initItem = name => ({ name: name }),
    selected: selectedHistory,
    // openToFolder = false,
    toolbarButton: ToolbarIconButton = CCIconButton,
    toolbarButtonProps = {},
    category
  } = props;

  const intl = useIntl();
  const classes = useStyles({ ...props });
  const [selectedItem, setSelectedItem] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedCmd, setSelectedCmd] = useState([]);
  const [move, setMove] = useState(null);

  const dialogReducer = (state, action) => {
    return { ...state, [action.type]: action.value };
  };
  const [dialog, dispatchDialog] = useReducer(dialogReducer, initDialog);

  const [contents, setContents] = useState({
    name: title || "root",
    items: treeData || []
  });
  const [searchField, setSearchField] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setContents({
      name: title || "root",
      items: treeData || []
    });
  }, [treeData, title]);

  useEffect(() => {
    setSelected(selectedHistory || []);
  }, [selectedHistory]);

  const insertItemHistory = (items, item, history, depth = 0) => {
    const newItems = [...items];

    if (history.length - 1 === depth) {
      newItems.splice(history[depth], 0, item);
      return newItems;
    } else {
      const newItem = {
        ...newItems[history[depth]],
        items: insertItemHistory(
          newItems[history[depth]].items,
          item,
          history,
          depth + 1
        )
      };
      newItems[history[depth]] = newItem;
    }

    return newItems;
  };

  const insertItem = async (items, name, depth = 0) => {
    const newItems = [...items];

    if (!selected.length) {
      const id = uuid();
      return items.concat(
        totalDepth - 1 === depth
          ? { name: name, state: false, id: id, item: await initItem(name, id) }
          : { name: name, state: false, id: id, items: [] }
      );
    } else if (selected.length - 1 === depth) {
      let newName = name;
      if (title === "문자") {
        const prefix = `(광고) {치과명}\n`;
        const suffix = `\n\n무료거부 {080-xxx-xxxx}`;

        newName = prefix + name + suffix;
      }

      const id = uuid();
      const newItem = {
        ...newItems[selected[depth]],
        items: newItems[selected[depth]].items.concat(
          totalDepth - 2 === depth
            ? { name: name, id: id, item: await initItem(newName, id) }
            : { name: name, state: false, id: id, items: [] }
        )
      };
      newItems[selected[depth]] = newItem;
    } else {
      const newItem = {
        ...newItems[selected[depth]],
        items: await insertItem(
          newItems[selected[depth]].items,
          name,
          depth + 1
        )
      };
      newItems[selected[depth]] = newItem;
    }

    return newItems;
  };

  const modItem = (items, name, depth = 0) => {
    const newItems = [...items];

    if (!selected.length) {
      return;
    } else if (selected.length - 1 === depth) {
      const newItem = {
        ...newItems[selected[depth]],
        name: name
      };
      newItems[selected[depth]] = newItem;
    } else {
      const newItem = {
        ...newItems[selected[depth]],
        items: modItem(newItems[selected[depth]].items, name, depth + 1)
      };
      newItems[selected[depth]] = newItem;
    }

    return newItems;
  };

  const deleteItem = (items, depth = 0) => {
    var dItem = {};
    const newItems = items.filter((item, index) => {
      if (index === selected[depth]) {
        if (selected.length - 1 === depth) {
          dItem = { ...item };
          return false;
        }
        return true;
      }
      return true;
    });

    if (!selected.length) {
      return [newItems, null];
    } else if (selected.length - 1 !== depth) {
      const [newItem, remove] = deleteItem(
        newItems[selected[depth]].items,
        depth + 1
      );
      dItem = remove;
      newItems[selected[depth]] = {
        ...newItems[selected[depth]],
        items: newItem
      };
    }
    return [newItems, dItem];
  };

  const options = useMemo(() => {
    const tmp = [];
    const foreach = (items, group, history) => {
      items.forEach((el, index) => {
        if (el.items) {
          foreach(el.items, group.concat(el.name), history.concat(index));
        } else {
          tmp.push({
            group: group.join(","),
            name: el.name,
            cho: extraction(el.name),
            history: history.concat(index),
            item: el
          });
        }
      });
    };

    foreach(contents.items, [], []);
    return tmp;
  }, [contents]);

  useEffect(() => {
    if (selectedCmd && ref.current) {
      ref.current.focus();
    }
  }, [selectedCmd, contents]);

  const selectedRef = useRef([]);
  const onChangeSelect = path => {
    selectedRef.current = path;
    setSelected(path);
  };

  useEffect(() => {
    if (treeData.length && selectedRef.current.length) {
      const changeItems = getItems(treeData, selectedRef.current);
      if (move === changeItems?.name) setMove(null);
      setSelectedItem(changeItems);
    }
  }, [treeData, totalDepth, move]);

  return (
    <Box className={clsx(classes.root, className)}>
      {!disabledSearch && (
        <Box className={classes.search}>
          <CCSearchMenu
            className={classes.search__field}
            options={options}
            groupBy={option => option.group}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            renderOption={option => option.name}
            filterOptions={(items, { inputValue }) =>
              items.filter(el => {
                if (
                  el.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                )
                  return true;
                if (el.name.indexOf(inputValue) !== -1) return true;
                if (!hangul.search(el.name, inputValue)) return true;
                if (el.cho && el.cho.indexOf(inputValue) !== -1) return true;
                return false;
              })
            }
            onChange={v => {
              if (v) {
                setSearchField(v);
                setSelectedCmd(v.history);
                onChangeSelect(v.history);
                const newItems = openFolder(contents.items, v.history);
                onChange({ type: "state", item: newItems, history: v.history });
                onClickItem && onClickItem(v.item, v.history);
              } else {
                setSearchField(null);
                setSelectedCmd([]);
                onChangeSelect([]);
              }
            }}
            value={searchField}
            selectOnFocus={true}
            autoHighlight={true}
          />
        </Box>
      )}
      {!disabledToolbar && (
        <Grid container className={classes.toolbar}>
          <Grid item>
            <ToolbarIconButton
              className={classes.icon__btn}
              onClick={() => {
                if (selected.length === totalDepth) {
                  console.log("err");
                  return;
                }
                // setAddDialog(true);
                dispatchDialog({
                  type: "add",
                  value: {
                    state: true,
                    value:
                      selected.length === totalDepth - 1
                        ? intl.formatMessage({
                            id: `ccItem`
                          })
                        : intl.formatMessage({
                            id: `ccFolder`
                          })
                  }
                });
              }}
              {...toolbarButtonProps}
            >
              <AddIcon />
            </ToolbarIconButton>
            <ToolbarIconButton
              className={classes.icon__btn}
              onClick={() => {
                if (selected.length === 0) {
                  console.log("err");
                  return;
                }

                dispatchDialog({
                  type: "mod",
                  value: { state: true, value: null }
                });
              }}
              {...toolbarButtonProps}
            >
              <ModifyIcon />
            </ToolbarIconButton>
            <ToolbarIconButton
              className={clsx(classes.icon__btn, classes.icon__delete)}
              onClick={() => {
                if (!selected.length) {
                  console.log("err");
                  return;
                }
                dispatchDialog({
                  type: "del",
                  value: { state: true, value: null }
                });
              }}
              {...toolbarButtonProps}
            >
              <DeleteIcon />
            </ToolbarIconButton>
          </Grid>
          <Grid item>
            <ToolbarIconButton
              className={classes.icon__btn}
              onClick={() => {
                if (Boolean(move)) return;

                let history = [...selected];
                let stateChangeItems = null;

                if (selected.length === 0) return;
                else {
                  if (!selected[0] && !selected[1]) return;
                  if (selected.length === 1) history[0] = history[0] - 1;
                  else {
                    const getPreviousFolderLength = (items, history) => {
                      let folderLength = items;
                      history.forEach((el, idx) => {
                        if (idx === selected.length - 1) {
                          folderLength = folderLength.length;
                          return;
                        }
                        folderLength = folderLength[el].items;
                      });
                      return folderLength;
                    };
                    if (history[history.length - 1] === 0) {
                      history[history.length - 2] =
                        history[history.length - 2] - 1;
                      const previousFolderLength = getPreviousFolderLength(
                        contents.items,
                        history
                      );
                      history[history.length - 1] = previousFolderLength;

                      stateChangeItems = changeStateTo(
                        contents.items,
                        history.slice(0, -1),
                        true
                      );
                    } else {
                      history[history.length - 1] =
                        history[history.length - 1] - 1;
                    }
                  }
                }

                const [newItems, delItem] = deleteItem(
                  stateChangeItems || contents.items
                );

                onChange({
                  type: "change",
                  item: insertItemHistory(newItems, delItem, history),
                  selected: history
                });
                setMove(selectedItem.name);
                onChangeSelect(history);
              }}
              {...toolbarButtonProps}
            >
              <MoveupIcon />
            </ToolbarIconButton>
            <ToolbarIconButton
              className={classes.icon__btn}
              onClick={() => {
                if (Boolean(move)) return;
                if (!selected.length) return;

                let history = [...selected];
                let stateChangeItems = null;

                const getLastIndex = (items, history, depth = 0) => {
                  if (history.length - 1 === depth) {
                    return items.length - 1;
                  } else {
                    return getLastIndex(
                      items[history[depth]].items,
                      history,
                      depth + 1
                    );
                  }
                };

                const isNextFolderExist = (items, history) => {
                  let folder = items;
                  let isExist = false;
                  for (let i = 0; i < history.length - 1; i++) {
                    if (i === history.length - 2) {
                      isExist = Boolean(
                        folder[history[history.length - 2] + 1]
                      );
                      break;
                    }
                    items = items[i].items;
                  }
                  return isExist;
                };

                const lastIndex = getLastIndex(contents.items, selected);

                if (lastIndex === selected[selected.length - 1]) {
                  const nextFolderExist = isNextFolderExist(
                    contents.items,
                    history
                  );
                  if (nextFolderExist) {
                    history[history.length - 2] =
                      history[history.length - 2] + 1;
                    history[history.length - 1] = 0;

                    stateChangeItems = changeStateTo(
                      contents.items,
                      history.slice(0, -1),
                      true
                    );
                  } else return;
                } else {
                  history[history.length - 1] = history[history.length - 1] + 1;
                }

                // const history = selected.map((el, index) => {
                //   if (index === selected.length - 1) return el + 1;
                //   return el;
                // });
                const [newItems, delItem] = deleteItem(
                  stateChangeItems || contents.items
                );

                onChange({
                  type: "change",
                  item: insertItemHistory(newItems, delItem, history),
                  selected: history
                });
                setMove(selectedItem.name);
                onChangeSelect(history);
              }}
              {...toolbarButtonProps}
            >
              <MovedownIcon />
            </ToolbarIconButton>
            <CCIconButton
              className={classes.icon__btn}
              onClick={() => {
                const newItems = changeStateAll(contents.items, false);
                onChange({ type: "state", item: newItems });
              }}
            >
              <FoldallIcon />
            </CCIconButton>
            {/* <CCIconButton
            className={classes.icon__btn}
            onClick={() => {
              const newItems = changeStateAll(contents.items, true);
              onChange && onChange(newItems);
            }}
          >
            <OpenfolderIcon />
          </CCIconButton> */}
          </Grid>
        </Grid>
      )}
      <Box className={classes.tree}>
        <div className={classes.tree__inner}>
          <div
            className={clsx({
              [classes.selected]: !selected.length,
              [classes.item]: true
            })}
            onClick={() => {
              onChangeSelect([]);
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <CCIconButton className={classes.fold__icon} disabled>
              <OpenfolderIcon />
            </CCIconButton>
            <div>{contents.name}</div>
          </div>
          <Items
            items={contents.items}
            onClick={(history, type, item) => {
              setSelectedCmd([]);
              setSearchField(null);
              onChangeSelect(history);
              setSelectedItem(item);

              if (type === "dir") {
                // if (openToFolder) {
                const newItems = changeState(contents.items, history);
                onChange({ type: "state", item: newItems, history: history });
                // }
                onClickDir(item, history);
                return;
              }

              // setSelectedItem(item);
              if (type === "item") onClickItem(item, history);
              if (type === "icon") {
                const newItems = changeState(contents.items, history);
                onChange({ type: "state", item: newItems, history: history });
              }
            }}
            selected={selected}
            selectedCmd={selectedCmd}
            totalDepth={totalDepth}
            ref={ref}
          />
        </div>
      </Box>
      {dialog.add.state && (
        <CCDialogSimpleAdd
          open={dialog.add.state}
          title={dialog.add.value}
          onClose={() => {
            dispatchDialog({
              type: "add",
              value: { state: false, value: null }
            });
          }}
          onSave={async value => {
            const newItems = await insertItem(contents.items, value);
            setContents({ name: title || "root", items: newItems });
            onChange({ type: "change", item: newItems });
            dispatchDialog({
              type: "add",
              value: { state: false, value: null }
            });
          }}
          maxLength={(() => {
            if (!selected.length) return 20;
            return 60;
          })()}
        />
      )}
      {dialog.mod.state && (
        <CCDialogSimpleMod
          title={intl.formatMessage({
            id: "ccModify"
          })}
          open={dialog.mod.state}
          onClose={() => {
            dispatchDialog({
              type: "mod",
              value: { state: false, value: null }
            });
          }}
          value={selectedItem?.name}
          onSave={value => {
            const newItems = modItem(contents.items, value);
            setContents({ name: title || "root", items: newItems });

            const needSync = nameSyncCategorys.includes(category);

            onChange({
              type: "change",
              item: newItems,
              needSync,
              selectedItem
            });

            dispatchDialog({
              type: "mod",
              value: { state: false, value: null }
            });
          }}
          maxLength={(() => {
            if (selectedItem?.hasOwnProperty("items")) return 20;
            return 60;
          })()}
        />
      )}
      <CCDialogSimpleDel
        open={dialog.del.state}
        onClose={() => {
          dispatchDialog({
            type: "del",
            value: { state: false, value: null }
          });
        }}
        onDisagree={() => {
          dispatchDialog({
            type: "del",
            value: { state: false, value: null }
          });
        }}
        onAgree={() => {
          const [newItems] = deleteItem(contents.items);
          onChange({ type: "change", item: newItems, selected: [] });
          onChangeSelect([]);
          dispatchDialog({
            type: "del",
            value: { state: false, value: null }
          });
        }}
      />
    </Box>
  );
};
export default CCTreeExp;
export { updateItem, getItems, deleteTreeState, openFolder, findHistory };
