import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import moment from "moment";
import propTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { useIntl } from "../../../locales/language";
import { DateIcon } from "../../themes/common/icons";
import CCTextFieldQuattro from "../CCTextFieldQuattro";
import CCTextFieldTrio from "../CCTextFieldTrio";
import CCFlattenDatePicker from "./CCFlattenDatePicker";
// import { getMoment } from "utils/datetime";

const useStyle = makeStyles(theme => ({
  root: {},
  button__height: {
    height: 36
  },
  width__100: {
    width: "100%"
  },
  chart__field: {
    marginLeft: 0,
    marginRight: 0
  },
  marginright__16: {
    marginRight: 16
  },
  marginright__8: {
    marginRight: 8
  },
  chart__input: {
    width: 118
  },
  timespent__input: {
    width: props => (Boolean(props?.disabledMeridiem) ? 171 / 2 : 149 / 2)
  },
  searchButton: {
    margin: 0
  },
  paddingright__0: {
    paddingRight: 0
  },
  paddingleft__0: {
    paddingLeft: 0
  },
  cellphoneField: {
    width: 194
  },
  dateButton: {
    color: theme.palette.icon
  }
}));

const getMoment = (timestamp = null) =>
  timestamp ? moment(timestamp) : moment();

function pad(n, width) {
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join("0") + n;
}

const MeridiemItems = function* () {
  const meridiem = Boolean(moment?.localeData()?._meridiemParse?.source)
    ? moment.localeData()._meridiemParse.source.split("|")
    : Boolean(moment?.localeData()?._meridiemParse)
    ? moment.localeData()._meridiemParse.split("|")
    : ["AM", "PM"];
  console.log("_meridiemParse", moment.localeData());
  for (let i in meridiem) {
    yield (
      <MenuItem key={"chart__field__meridiem__menuitem" + i} value={i}>
        <div dangerouslySetInnerHTML={{ __html: meridiem[i] }}></div>
      </MenuItem>
    );
  }
};

const InputMenuHourItems = function* (count, value, label) {
  for (let i = 0; i < count; i++) {
    if (value.hours() >= 12) {
      if (i === 11) {
        yield (
          <MenuItem key={"MenuHourItem" + i} value={i + 1}>
            {getMoment()
              .hours(pad(i + 1, 2))
              .format("hh")}
          </MenuItem>
        );
      } else {
        yield (
          <MenuItem key={"MenuHourItem" + i} value={i + 1 + 12}>
            {getMoment()
              .hours(pad(i + 1, 2))
              .format("hh")}
          </MenuItem>
        );
      }
    } else {
      yield (
        <MenuItem key={"MenuHourItem" + i} value={(i + 1) % 12}>
          {getMoment()
            .hours(pad(i + 1, 2))
            .format("hh")}
        </MenuItem>
      );
    }
  }
};

const Input24MenuHourItems = function* (count, value, label) {
  for (let i = 0; i < count; i++) {
    yield (
      <MenuItem key={"MenuHourItem" + i} value={i}>
        {getMoment().hours(pad(i, 2)).format("H")}
      </MenuItem>
    );
  }
};

const InputMenuTimeItems = function* (count, step, label) {
  for (let i = 0; i < count; i++) {
    if (i % step !== 0) continue;
    yield (
      <MenuItem key={"MenuTimeItem" + i} value={i}>
        {getMoment().minutes(pad(i, 2)).format("mm")}
      </MenuItem>
    );
  }
};

const CCFlattenDateTimeFieldQuattro = forwardRef((props, ref) => {
  const intl = useIntl();
  const {
    value,
    onChange,
    readOnly,
    step,
    inputRef,
    onBlur,
    required,
    error,
    name,
    dateLabel = intl.formatMessage({ id: "ccDate" }),
    hoursLabel = intl.formatMessage({ id: "ccTimeUnit" }),
    minutesLabel = intl.formatMessage({ id: "ccMinuteUnit" }),
    meridiemLabel = intl.formatMessage({ id: "ccMeridiem" }),
    hoursCountLabel = intl.formatMessage({ id: "ccTimeUnit" }),
    minutesCountLabel = intl.formatMessage({ id: "ccMinuteUnit" }),
    disabledMeridiem = Boolean(
      localStorage.getItem("lang") !== "en" &&
        localStorage.getItem("lang") !== "ko" &&
        localStorage.getItem("lang") !== "ar" &&
        localStorage.getItem("lang") !== "en-eg"
    ),
    ...others
  } = props;

  const classes = useStyle({ disabledMeridiem });
  const [pickerOpen, setPickerOpen] = useState(false);
  const [momentValue, setMomentValue] = useState(value);
  const [textValue, setTextValue] = useState(value.format("L"));
  useEffect(() => {
    setTextValue(value.format("L"));
  }, [value]);
  useEffect(() => {
    if (value.format("L") !== momentValue.format("L")) setMomentValue(value);
  }, [value, momentValue]);

  return (
    <>
      <CCFlattenDatePicker
        open={pickerOpen}
        value={momentValue}
        onChange={e => {
          onChange(e.second(0).millisecond(0));
          setPickerOpen(false);
        }}
        onClose={() => {
          setPickerOpen(false);
        }}
      />
      {Boolean(disabledMeridiem) && (
        <CCTextFieldTrio
          {...others}
          variant={"outlined"}
          margin={"dense"}
          LeftProps={{
            label: dateLabel,
            error: Boolean(error),
            value: textValue,
            required: required,
            inputRef: ref,
            onChange: e => {
              setTextValue(e.target.value);
            },
            onClickIcon: () => setPickerOpen(true),
            onBlur: () => setTextValue(value.format("L")),
            InputProps: {
              readOnly: true
            },
            endIcon: <DateIcon className={clsx(classes.dateButton)} />
          }}
          MidProps={{
            label: hoursLabel,
            error: error,
            InputProps: {
              className: clsx(classes.timespent__input)
            },
            required: required,
            InputLabelProps: {
              shrink: true
            },
            value: value.hours(),
            select: true,
            onChange: e => {
              // console.log("test", e.target.value);
              onChange(
                value.clone().hours(e.target.value).second(0).millisecond(0)
              );
            },
            children: [
              ...Input24MenuHourItems(24, value.clone(), hoursCountLabel)
            ]
          }}
          RightProps={{
            label: minutesLabel,
            error: Boolean(error),
            InputProps: {
              className: clsx(classes.timespent__input)
            },
            required: required,
            InputLabelProps: {
              shrink: true
            },
            value: value.minutes(),
            select: true,
            onChange: e => {
              onChange(
                value.clone().minutes(e.target.value).second(0).millisecond(0)
              );
            },
            children: [...InputMenuTimeItems(60, step, minutesCountLabel)]
          }}
        />
      )}
      {!Boolean(disabledMeridiem) && (
        <CCTextFieldQuattro
          {...others}
          variant={"outlined"}
          margin={"dense"}
          LeftProps={{
            label: dateLabel,
            error: Boolean(error),
            value: textValue,
            required: required,
            inputRef: ref,
            onChange: e => {
              setTextValue(e.target.value);
            },
            onClickIcon: () => setPickerOpen(true),
            onBlur: () => setTextValue(value.format("L")),
            InputProps: {
              readOnly: true
            },
            endIcon: <DateIcon className={clsx(classes.dateButton)} />
          }}
          MidProps={{
            label: hoursLabel,
            error: error,
            InputProps: {
              className: clsx(classes.timespent__input)
            },
            required: required,
            InputLabelProps: {
              shrink: true
            },
            value: value.hours(),
            select: true,
            onChange: e => {
              onChange(
                value.clone().hours(e.target.value).second(0).millisecond(0)
              );
            },
            children: [
              ...InputMenuHourItems(12, value.clone(), hoursCountLabel)
            ]
          }}
          Mid2Props={{
            label: minutesLabel,
            error: Boolean(error),
            InputProps: {
              className: clsx(classes.timespent__input)
            },
            required: required,
            InputLabelProps: {
              shrink: true
            },
            value: value.minutes(),
            select: true,
            onChange: e => {
              onChange(
                value.clone().minutes(e.target.value).second(0).millisecond(0)
              );
            },
            children: [...InputMenuTimeItems(60, step, minutesCountLabel)]
          }}
          RightProps={{
            label: meridiemLabel,
            error: Boolean(error),
            required: required,
            InputProps: {
              className: clsx(classes.timespent__input)
            },
            InputLabelProps: {
              shrink: true
            },
            value: value.hours() < 12 ? 0 : 1,
            select: true,
            onChange: e => {
              if (e.target.value === "1") {
                if (value.hours() < 12) {
                  let _res = value.clone().add(12, "hours");
                  onChange && onChange(_res.second(0).millisecond(0));
                }
              } else {
                if (value.hours() >= 12) {
                  let _res = value.clone().subtract(12, "hours");
                  onChange && onChange(_res.second(0).millisecond(0));
                }
              }
            },
            children: [...MeridiemItems()]
          }}
        />
      )}
    </>
  );
});

CCFlattenDateTimeFieldQuattro.propTypes = {
  value: propTypes.instanceOf(moment).isRequired,
  onChange: propTypes.func.isRequired,
  readOnly: propTypes.bool,
  step: propTypes.number
};
CCFlattenDateTimeFieldQuattro.defaultProps = {
  step: 10
};

export default CCFlattenDateTimeFieldQuattro;
