import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export const CheckLabIdExistDocument = gql`
  query checkLabIdExist($id: String!) {
    checkLabIdExist(id: $id)
  }
`;

export function useCheckLabIdExistLazyQuery(baseOptions) {
  return useLazyQuery(CheckLabIdExistDocument, baseOptions);
}
