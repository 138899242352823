import overrides from "../overrides";
import palette from "./palette";
const theme = {
  palette,
  overrides,
  mixins: {
    sidebar: {
      width: 80
    },
    topbar: {
      height: 68
    }
  },
  zIndex: {
    default: 100,
    permissionBackdrop: 1000,
    loadingBackdrop: 2000
  }
};

export default theme;
