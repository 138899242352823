import { useTheme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { CCColorPicker, CCTextField } from "../../../components";
import { ColorpickerIcon } from "../../../icons";

const PickerColorRect = props => {
  const { color = "rgba(255,255,255,1)" } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      width={16}
      height={16}
      style={{ borderRadius: 5, border: "1px solid #000" }}
      viewBox={"0 0 24 24"}
      // className={clsx(classes.colorIcon)}
    >
      <rect width={24} height={24} fill={color} />
    </svg>
  );
};

const CCColorField = props => {
  const theme = useTheme();
  const { value = "rgba(255,255,255,1)", onChange, ...others } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <CCTextField
        label={"Color(bg)"}
        startIcon={<PickerColorRect color={value} />}
        endIcon={<ColorpickerIcon color={theme.palette.secondary.main} />}
        inputProps={{
          style: {
            width: 0
          }
        }}
        onClickIcon={e => {
          setOpen(true);
        }}
        {...others}
      />
      <CCColorPicker
        value={value || "rgba(255,255,255,1)"}
        open={open}
        onChange={e => {
          onChange && onChange(`rgba(${e.r},${e.g},${e.b},${e.a})`);
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

CCColorField.defaultProps = {
  label: "Color(bg)",
  margin: "dense",
  variant: "outlined"
};

export default CCColorField;
