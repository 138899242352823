import palette from "../default/palette";
import shape from "../default/shape";

const fontSizeDense = 14;
const fontSizeNormal = 16;
const inputHeight = 20;
const adornedPadding = 8;

const placeholderDense = {
  fontSize: fontSizeDense
};

export default {
  root: {
    borderRadius: shape.borderRadius,
    "&:hover $notchedOutline": {
      borderColor: palette.input.hover
    },
    "@media (hover: none)": {
      "&:hover $notchedOutline": {
        borderColor: palette.input.main
      }
    },
    "&$focused $notchedOutline": {
      borderColor: palette.primary.light,
      borderWidth: 2,
      borderRadius: shape.borderRadius
    },
    "&$error $notchedOutline": {
      borderColor: palette.error.main,
      borderWidth: 2
    }
  },
  multiline: {
    padding: "18.5px 10px"
  },
  notchedOutline: {
    borderColor: palette.input.main,
    "& legend": { fontSize: "0.85em !important" }
  },
  input: {
    height: inputHeight,
    padding: "14px 10px",
    "&$disabled": {
      borderRadius: shape.borderRadius,
      backgroundColor: palette.inactive.main
    },
    fontSize: fontSizeNormal
  },
  inputMarginDense: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: fontSizeDense,
    "&::-webkit-input-placeholder": placeholderDense,
    "&::-moz-placeholder": placeholderDense, // Firefox 19+
    "&:-ms-input-placeholder": placeholderDense, // IE 11
    "&::-ms-input-placeholder": placeholderDense // Edge
  },
  inputMultiline: {
    height: "auto",
    padding: 0
  },
  adornedStart: {
    paddingLeft: adornedPadding
  },
  adornedEnd: {
    paddingRight: adornedPadding
  }
};
