const mergeArrayByKey = (arr1, arr2, key = "key") => {
  let merged = [];

  for (let i = 0; i < arr1.length; i++) {
    merged.push({
      ...arr1[i],
      ...arr2.find(itmInner => itmInner[key] === arr1[i][key])
    });
  }

  return merged;
};

const sortArrayByKey = ({
  array,
  sortKey,
  isSortKeyString = true,
  ascending = true
}) => {
  if (isSortKeyString) {
    return array.sort(function (a, b) {
      let nameA = a[sortKey].toLowerCase();
      let nameB = b[sortKey].toLowerCase();
      if (ascending) {
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      }

      if (nameA < nameB)
        //sort string ascending
        return 1;
      if (nameA > nameB) return -1;
      return 0; //default return value (no sorting)
    });
  }

  return array.sort(function (a, b) {
    let nameA = a[sortKey];
    let nameB = b[sortKey];
    if (ascending) {
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    }

    if (nameA < nameB)
      //sort string ascending
      return 1;
    if (nameA > nameB) return -1;
    return 0; //default return value (no sorting)
  });
};

export { mergeArrayByKey, sortArrayByKey };
