import { useState, useEffect } from "react";
import moment from "moment";
import { NEGATIVE_ALPHABET_NUMERIC_SPECIAL_CHARACTER_REGEX } from "./constants";

export const formatUnixInSecToYYYYMMDD = date => {
  if (!date) return date;

  return moment(date * 1000).format("YYYY/MM/DD");
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

export const removeNonAlphabetNumericAndSpecialCharacter = (str = "") => {
  return str.replace(NEGATIVE_ALPHABET_NUMERIC_SPECIAL_CHARACTER_REGEX, "");
};

export const reorderArray = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const stringToSlug = str => {
  // remove accents
  let from =
      "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str
    .toLowerCase()
    // .trim()
    // .replace(/[^a-z0-9\-]/g, "-")
    // .replace(/-+/g, "-");

  return str;
};
