import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { forwardRef } from 'react';
import { CCIconButton, CCTypography } from 'styles/components';
import { CheckIcon, RemoveIcon } from 'styles/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    color: 'white',
    opacity: 0.54,
    width: '100vw',
    minWidth: 500,
    borderRadius: 8,
    height: 48,
    padding: '0 16px',
    alignItems: 'center',
  },
  start__icon: {
    height: 24,
    marginRight: 16,
    color: theme.palette.primary.main,
  },
  close__icon: {
    color: 'white',
  },
  containerAnchorOriginBottomCenter: {},
  typography: {
    color: 'white',
  },
  link: {
    color: 'white',
    cursor: 'pointer',
    margin: '0 8px',
  },
  container: {
    justifyContent: 'center',
  },
}));

const Toast = forwardRef((props, ref) => {
  const { snackKey, message, links, onClose } = props;
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  return (
    <Grid container ref={ref} className={classes.root}>
      <Grid item style={{ flex: 'auto' }}>
        <Grid container className={classes.container}>
          <Grid item className={classes.start__icon}>
            <CheckIcon />
          </Grid>
          <Grid item style={{ alignSelf: 'center' }}>
            <CCTypography className={classes.typography}>
              {message}
            </CCTypography>
          </Grid>
        </Grid>
      </Grid>
      {links && (
        <Grid item>
          {links.map((link, key) => {
            return (
              <Link
                key={key}
                herf='#'
                underline='always'
                className={classes.link}
                onClick={() => {
                  link.onClick(snackKey);
                }}
              >
                {link.message}
              </Link>
            );
          })}
        </Grid>
      )}
      {onClose && (
        <Grid item>
          <CCIconButton
            className={classes.close__icon}
            onClick={() => {
              closeSnackbar(snackKey);
            }}
          >
            <RemoveIcon />
          </CCIconButton>
        </Grid>
      )}
    </Grid>
  );
});

export default Toast;
