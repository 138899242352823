const NoticeIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4823 0.817026C10.6781 0.885054 10.7836 1.0967 10.7233 1.29323L10.3467 2.42703C10.2789 2.62356 10.068 2.72938 9.87211 2.66891C9.67626 2.60088 9.57081 2.38924 9.63107 2.19271L10.0077 1.0589C10.0755 0.862378 10.2864 0.756556 10.4823 0.817026ZM13.2693 2.20027C13.42 2.0491 13.42 1.81478 13.2693 1.6636C13.1187 1.51243 12.8852 1.51243 12.7345 1.6636L11.228 3.17535C11.0773 3.32652 11.0773 3.56084 11.228 3.71202C11.3786 3.86319 11.6121 3.86319 11.7628 3.71202L13.2693 2.20027ZM5.54839 2.41947C6.00035 1.45952 7.28089 1.25543 8.00402 2.03398L12.968 7.30997C13.6836 8.06584 13.435 9.31303 12.4784 9.72876L9.51054 11.0364C9.57081 11.2632 9.60847 11.5051 9.60847 11.7545C9.60847 13.2133 8.42585 14.4 6.97206 14.4C6.02295 14.4 5.19436 13.9012 4.73487 13.1529L3.83096 13.5535C3.40913 13.7424 2.91198 13.6517 2.58054 13.3192L1.66157 12.4045C1.3226 12.0644 1.23221 11.5504 1.43559 11.112L5.54839 2.41947ZM5.4354 12.843C5.77437 13.3267 6.33932 13.6442 6.97959 13.6442C8.01909 13.6442 8.86274 12.7976 8.86274 11.7545C8.86274 11.6109 8.84767 11.4748 8.81754 11.3463L5.4354 12.843ZM12.6215 4.57371C12.4106 4.57371 12.2449 4.74 12.2449 4.95165C12.2449 5.16329 12.4106 5.32958 12.6215 5.32958H13.7514C13.9623 5.32958 14.128 5.16329 14.128 4.95165C14.128 4.74 13.9623 4.57371 13.7514 4.57371H12.6215Z"
      fill="currentColor"
    />
  </svg>
);

export default NoticeIcon;
