import {
  ThemeProvider,
  createMuiTheme,
  makeStyles
} from "@material-ui/core/styles";
import { AppContextProvider } from "context";
import { AppContext } from "context/AppContext";
import { en, ko } from "locales";
import { useContext } from "react";
import { IntlProvider } from "react-intl";
import { ApolloProvider } from "components";
import { RouteContextProvider } from "context";
import moment from "moment";
import { LocalizeVariable } from "utils/env";
import { useLanguage } from "utils/language";
import { ToastProvider } from "providers/ToastProvider";

const useStyles = makeStyles(() => ({
  root: { width: "100vw" },
  containerAnchorOriginBottomCenter: {}
}));

const loadLocale = () => {
  const currentLang =
    localStorage.getItem("lang") ||
    process.env.REACT_APP_DEFAULT_LANGUAGE ||
    "en";

  moment.locale(currentLang);

  moment.updateLocale(currentLang, {
    week: {
      dow: LocalizeVariable.startOfWeek
    }
  });
};

const NeedAppContext = props => {
  const { children } = props;
  const classes = useStyles();
  const { appContext } = useContext(AppContext);
  const [language] = useLanguage();
  const messages = { ko: ko, en: en };

  loadLocale();

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <ThemeProvider theme={createMuiTheme(appContext.theme)}>
        <ToastProvider>{children}</ToastProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

const ContextProvider = props => {
  const { children } = props;

  return (
    <AppContextProvider>
      <RouteContextProvider>
        <ApolloProvider>
          <NeedAppContext children={children} />
        </ApolloProvider>
      </RouteContextProvider>
    </AppContextProvider>
  );
};

export default ContextProvider;
