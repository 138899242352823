import { Grid, makeStyles } from "@material-ui/core";
import MaterialUiPhoneNumber from "components/InputPhoneNumber";
import { useIntl } from "react-intl";
import { LocalizeVariable } from "utils/env";

const useStyles = makeStyles(
  () => ({
    root: {
      height: 36
    },
    input__ellipsis: { textOverflow: "ellipsis", overflow: "hidden" },
    notchedOutline: {},
    marginDense: {
      fontSize: props => `${props.labelFontSize}px !important`
    },
    input: {
      fontSize: "14px !important",
      direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
    },
    cover: {
      position: "relative",
      display: "inline",
      width: "100%"
    },
    disabled_click_flag: {
      position: "absolute",
      zIndex: 1,
      top: 8,
      left: 18,
      width: 40,
      height: 40
    }
  }),
  { name: "PhoneNumberRegionField" }
);

const PhoneNumberRegionField = props => {
  const intl = useIntl();
  const classes = useStyles(props);
  const {
    label = intl.formatMessage({ id: "phoneNumber" }),
    InputProps,
    disabledClickFlag
  } = props;

  return (
    <Grid className={classes.cover}>
      <MaterialUiPhoneNumber
        label={label}
        placeholder={"Input Phone Number"}
        countryCallingCodeEditable={false}
        initialValueFormat="national"
        defaultCountry={LocalizeVariable.countryPhoneCode}
        variant="outlined"
        margin="dense"
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
            notchedOutline: classes.notchedOutline
          },
          ...InputProps
        }}
        countryCodeEditable={false}
        disableAreaCodes={true}
        dir="ltr"
        {...props}
      />
      {disabledClickFlag && <div className={classes.disabled_click_flag} />}
    </Grid>
  );
};
PhoneNumberRegionField.defaultProps = {
  labelLegendSize: 0.75,
  labelFontSize: localStorage.getItem("lang") === "ru" ? 12 : 14
};
export default PhoneNumberRegionField;
