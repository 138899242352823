import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import { Switch, translate } from "components";
import confirm from "components/confirm";
import { useCreateLabApplicationRequestMutation } from "graphql/mutations";
import {
  useCheckBRNExistLazyQuery,
  useCheckLabIdExistLazyQuery
} from "graphql/queries";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { ACCOUNT_TYPE_VALUES } from "types/registrationManage";
import { consoleLog } from "utils/consoleLog";
import { PasswordRegExp, REGEX_PHONE_NUMBER } from "utils/constants";
import { LocalizeVariable } from "utils/env";
import { Target, getObject, putObject } from "utils/s3";
import { removeNonAlphabetNumericAndSpecialCharacter } from "utils/utils";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import { ENTRY_LIMIT, TEXT_FIELD_LENGTH } from "../../../../../types/constants";

const EmailRegExp =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*(\.[a-zA-Z]{2,})$/i;

const useStyles = makeStyles(() => ({
  root: {
    width: "inherit",
    margin: 16,
    border: "1px solid #b0bec5",
    borderRadius: 8,
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 251px)",
    flexWrap: "nowrap",
    flexDirection: "column"
  },
  content: {
    padding: 16,
    height: "100%",
    flexWrap: "nowrap"
  },
  header: {
    fontWeight: "bold",
    padding: "8px 16px",
    borderBottom: "1px solid #b0bec5",
    width: "100%"
  },
  section__header: {
    fontStyle: "italic",
    color: "gray"
  },
  inputWidth500: {
    flex: 1
  },
  inputMargin: {
    margin: "8px 0"
  },
  phoneRegion: {
    margin: "8px 0 !important",
    width: "100%"
  },
  action__button: {
    height: 40,
    marginLeft: 16,
    minWidth: 250
  }
}));

const REGEX_NUMBER = /^[\d-]+$/;
const ApplicationForm = ({ onSuccess, onError }, ref) => {
  const [labIdCheck] = useCheckLabIdExistLazyQuery();
  const [checkBRN] = useCheckBRNExistLazyQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [registerNewLab, { loading }] = useCreateLabApplicationRequestMutation({
    onCompleted: onSuccess,
    onError
  });

  const classes = useStyles();
  const intl = useIntl();

  const submitBtnRef = useRef();

  const schema = yup
    .object({
      labName: yup
        .string()
        .required(translate(intl, "signUp.error.required"))
        .test(
          "labName",
          translate(intl, "signUp.error.labNameLength"),
          (value = "") => {
            return value.trim().length >= 2 && value.trim().length <= 20;
          }
        ),
      labTel: yup.string().required(translate(intl, "signUp.error.required")),
      businessRegistration: yup.object(),
      // .required(translate(intl, "signUp.error.required"))
      // .when("labName", (_, field) => {
      //   if (LocalizeVariable.isKorean)
      //     return field.required(translate(intl, "signUp.error.required"));

      //   return field;
      // }),
      labBusinessRegistrationNumber: yup
        .string()
        // .required(translate(intl, "signUp.error.required"))
        .when("labName", (_, field) => {
          if (LocalizeVariable.isKorean)
            return field.required(translate(intl, "signUp.error.required"));

          return field;
        })
        .test(
          "labBusinessRegistrationNumber",
          translate(intl, "signUp.error.brnExists"),
          async value => {
            if (LocalizeVariable.isKorean) {
              try {
                const { data } = await checkBRN({
                  variables: {
                    brn: value || ""
                  }
                });

                return Boolean(data && !data?.checkBRNExist);
              } catch (error) {
                return false;
              }
            }
            return true;
          }
        )
        .test(
          "labBusinessRegistrationNumber",
          translate(intl, "signUp.error.brnNumber"),
          value => {
            // console.log("value", value);
            // if (value) {
            //   const result = REGEX_NUMBER.test(value);
            //   console.log("result", result);
            //   return result;
            // }

            return true;
          }
        ),
      labManagerName: yup
        .string()
        .required(translate(intl, "signUp.error.required"))
        .test(
          "labManagerName",
          translate(intl, "signUp.error.managerNameLength"),
          (value = "") => {
            return value.trim().length >= 2 && value.trim().length <= 20;
          }
        ),
      labId: yup
        .string()
        .required(translate(intl, "signUp.error.required"))
        // .test("strongLadId", translate(intl, "signUp.error.labId"), value => {
        //   return ID_VALIDATION_REGEXP.test((value || "").trim());
        // })
        .test(
          "ValidateLabIdLength",
          translate(intl, "signUp.error.labId"),
          (value = "") => {
            return value.trim().length >= 4 && value.trim().length <= 20;
          }
        )
        .test(
          "validLabId",
          translate(intl, "signUp.ui.idUsed"),
          async value => {
            try {
              const { data } = await labIdCheck({
                variables: {
                  id: value || ""
                }
              });

              return Boolean(data && !data?.checkLabIdExist);
            } catch (error) {
              return false;
            }
          }
        ),
      staffId: yup
        .string()
        .required(translate(intl, "signUp.error.required"))
        // .test("id", translate(intl, "signUp.error.staffId"), value => {
        //   return ID_VALIDATION_REGEXP.test((value || "").trim());
        // })
        .test(
          "ValidateStaffIdLength",
          translate(intl, "signUp.error.staffId"),
          (value = "") => {
            return value.trim().length >= 4 && value.trim().length <= 20;
          }
        ),
      password: yup
        .string()
        .required(translate(intl, "signUp.error.required"))
        .test(
          "ValidateStaffIdLength",
          translate(intl, "signUp.error.password"),
          (value = "") => {
            return value.trim().length >= 8 && value.trim().length <= 20;
          }
        )
        .test(
          "strongPassword",
          translate(intl, "signUp.error.password"),
          value => {
            return PasswordRegExp.test(value);
          }
        ),
      verifyPassword: yup
        .string()
        .test(
          "verifyPassword",
          translate(intl, "signUp.error.confirmPassword"),
          function (value) {
            const { password } = this.parent;
            return password === value;
          }
        ),
      cellNo: yup.string().required(translate(intl, "signUp.error.required")),
      otp: yup.string(),
      referral: yup.string().transform((value, originValue) => {
        return value ? value?.trim() : value;
      })
      // .test(
      //   "referralLength",
      //   translate(intl, "signUp.error.referralLength"),
      //   (value = "") => {
      //     if (value) {
      //       return value.trim().length >= 2 && value.trim().length <= 20;
      //     }
      //     return true;
      //   }
      // )
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const {
    formState: { errors: formErrors }
  } = methods;

  useImperativeHandle(ref, () => ({
    submit: () => {
      submitBtnRef.current?.click();
    },
    reset: () => {
      methods.reset();
    }
  }));

  const onSubmit = async data => {
    if (loading) {
      return;
    }
    try {
      const input = {
        labName: data.labName,
        labTel: data.labTel,
        labBusinessRegistrationNumber: data.labBusinessRegistrationNumber,
        labManagerName: data.labManagerName,
        labId: data.labId,
        staffId: data.staffId,
        password: data.password,
        cellNo: data.cellNo,
        accountType: data.accountType
          ? ACCOUNT_TYPE_VALUES.TEST.value
          : ACCOUNT_TYPE_VALUES.LAB.value,
        businessRegistration: {
          ...data.businessRegistration,
          target: Target.PHOTO
        },
        referral: data.referral
      };

      methods.reset();
      await registerNewLab({
        variables: {
          input
        }
      });
    } catch (error) {
      consoleLog(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUploadFile = async (event, onChange) => {
    event.preventDefault();
    const selectedFile = event.target.files;

    try {
      const acceptedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxFileSize = 10 * 1024 * 1024;
      const fileType = selectedFile?.[0]?.type;
      const fileSize = selectedFile?.[0]?.size;
      if (!acceptedFileTypes.includes(fileType)) {
        console.error(`fileType ${fileType} is not supported`);
        return confirm({
          type: "error",
          title: translate(intl, "common.error.invalidFileType"),
          cancelTitle: translate(intl, "common.ui.ok")
        });
      }

      if (fileSize > maxFileSize) {
        console.error(`fileSize ${fileSize} is too large`);
        return confirm({
          type: "error",
          title: translate(intl, "common.error.invalidFileSize"),
          cancelTitle: translate(intl, "common.ui.ok")
        });
      }

      const fileUUID = uuid();
      await putObject(
        Target.PHOTO,
        fileUUID,
        selectedFile?.[0]?.name,
        selectedFile?.[0]
      );

      const url = await getObject(
        Target.PHOTO,
        fileUUID,
        selectedFile?.[0]?.name
      );

      const fileData = {
        type: selectedFile?.[0]?.type,
        url,
        id: fileUUID,
        name: selectedFile?.[0]?.name
      };
      onChange(fileData);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form
      id="registration-form"
      onSubmit={e => {
        if (isSubmitting) {
          return e.preventDefault();
        }
        setIsSubmitting(true);
        methods.handleSubmit(onSubmit, () => {
          setIsSubmitting(false);
        })(e);
      }}
    >
      <Grid container>
        <div className={classes.content}>
          <Box mb={2}>
            <Typography className={classes.section__header}>
              {translate(intl, "signUp.ui.registration.afterRegistrationGuide")}
            </Typography>
          </Box>

          <Box display="flex">
            <Box mr={1} mt={2} minWidth={120}>
              <Typography>
                {translate(intl, "common.ui.labName")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <TextField
              className={clsx(classes.inputWidth500, classes.inputMargin)}
              variant={"outlined"}
              margin={"dense"}
              placeholder={translate(intl, "common.ui.labName")}
              error={Boolean(formErrors.labName)}
              InputProps={{
                inputProps: {
                  maxLength: ENTRY_LIMIT.NUMBER_FIELD
                }
              }}
              helperText={formErrors.labName?.message || ""}
              {...methods.register("labName")}
            />
          </Box>

          <Box display="flex">
            <Box mr={1} mt={2} minWidth={120}>
              <Typography>
                {translate(intl, "common.ui.telNo")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>

            <Controller
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  className={clsx(classes.inputWidth500, classes.inputMargin)}
                  variant={"outlined"}
                  margin={"dense"}
                  placeholder={translate(intl, "common.ui.telNo")}
                  error={Boolean(formErrors.labTel)}
                  helperText={formErrors.labTel?.message || ""}
                  value={value ?? ""}
                  onChange={event => {
                    const value = event.target.value;
                    const isValid = REGEX_PHONE_NUMBER.test(value);
                    if (isValid) {
                      onChange(event);
                    }
                  }}
                />
              )}
              name="labTel"
            />
          </Box>

          <Box display="flex" alignItems={"center"} py="8px">
            <Box mr={1} minWidth={120}>
              <Typography>
                {translate(intl, "common.ui.brc")}
                {/* {LocalizeVariable.isKorean ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )} */}
              </Typography>
            </Box>

            <Controller
              name="businessRegistration"
              control={methods.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    className={clsx(classes.inputWidth500, classes.inputMargin)}
                    variant={"outlined"}
                    margin={"dense"}
                    placeholder={translate(intl, "common.ui.brc")}
                    error={Boolean(formErrors.businessRegistration)}
                    type="file"
                    InputProps={{
                      inputProps: {
                        accept: "image/png,image/jpg,image/jpeg"
                      }
                    }}
                    helperText={formErrors.businessRegistration?.message || ""}
                    onChange={event => handleUploadFile(event, onChange)}
                  />
                );
              }}
            />
          </Box>

          <Box display="flex" alignItems={"center"}>
            <Box mr={1} minWidth={120} style={{ width: 20 }}>
              <Typography>
                {translate(intl, "common.ui.brn")}
                {LocalizeVariable.isKorean ? (
                  <span style={{ color: "red" }}>*</span>
                ) : null}
              </Typography>
            </Box>

            <Controller
              name="labBusinessRegistrationNumber"
              control={methods.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    className={clsx(classes.inputWidth500, classes.inputMargin)}
                    variant={"outlined"}
                    margin={"dense"}
                    placeholder={translate(intl, "common.ui.brn")}
                    value={value}
                    error={Boolean(formErrors.labBusinessRegistrationNumber)}
                    InputProps={{
                      inputProps: {
                        maxLength: ENTRY_LIMIT.TEXT_FIELD.SHORT
                      }
                    }}
                    onChange={e => {
                      onChange(
                        // removeNonAlphabetNumericAndSpecialCharacter(
                        //   e.target.value
                        // )
                        e.target.value
                      );
                    }}
                    helperText={
                      formErrors.labBusinessRegistrationNumber?.message || ""
                    }
                    // {...methods.register("labBusinessRegistrationNumber")}
                  />
                );
              }}
            />
          </Box>

          <Box display="flex">
            <Box mr={1} mt={2} minWidth={120}>
              <Typography>
                {translate(intl, "common.ui.managerName")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <TextField
              className={clsx(classes.inputWidth500, classes.inputMargin)}
              variant={"outlined"}
              margin={"dense"}
              placeholder={translate(intl, "common.ui.managerName")}
              error={Boolean(formErrors.labManagerName)}
              InputProps={{
                inputProps: {
                  maxLength: ENTRY_LIMIT.NUMBER_FIELD
                }
              }}
              helperText={formErrors.labManagerName?.message || ""}
              {...methods.register("labManagerName")}
            />
          </Box>

          <Box display="flex">
            <Box mr={1} mt={2} minWidth={120}>
              <Typography>
                {translate(intl, "common.ui.cellNo")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <Box display="flex" flex={1} flexDirection="column">
              <Box display="flex" flex={1} alignItems="center">
                <Controller
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      className={clsx(
                        classes.inputWidth500,
                        classes.inputMargin
                      )}
                      variant={"outlined"}
                      margin={"dense"}
                      placeholder={translate(intl, "common.ui.cellNo")}
                      error={Boolean(formErrors.cellNo)}
                      helperText={formErrors.cellNo?.message || ""}
                      value={value ?? ""}
                      onChange={event => {
                        const value = event.target.value;
                        const isValid = REGEX_PHONE_NUMBER.test(value);
                        if (isValid) {
                          onChange(event);
                        }
                      }}
                    />
                  )}
                  name="cellNo"
                />

                {/* <Button variant="contained" color="primary" className={classes.action__button}>
                    {translate(intl, "signUp.ui.sendAuthentication")}
                  </Button> */}
              </Box>

              {/* <Box display="flex" flex={1} alignItems="center">
                  <TextField
                    className={clsx(classes.inputWidth500, classes.inputMargin)}
                    variant={"outlined"}
                    margin={"dense"}
                    error={Boolean(formErrors.otp)}
                    InputProps={{
                      inputProps: {
                        maxLength: ENTRY_LIMIT.NAME,
                      },
                    }}
                    helperText={formErrors.otp?.message || ""}
                    {...methods.register("otp")}
                  />
                  <Button variant="contained" color="primary" className={classes.action__button}>
                    {translate(intl, "check")}
                  </Button>
                </Box> */}

              <Button
                style={{ display: "none" }}
                ref={submitBtnRef}
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Box>

          <Box display="flex" alignItems={"center"}>
            <Box mr={1} minWidth={120}>
              <Typography>
                {translate(intl, "common.ui.labId")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <TextField
              className={clsx(classes.inputWidth500, classes.inputMargin)}
              variant={"outlined"}
              margin={"dense"}
              placeholder={translate(intl, "common.ui.labId")}
              error={Boolean(formErrors.labId)}
              InputProps={{
                inputProps: {
                  maxLength: ENTRY_LIMIT.NUMBER_FIELD
                }
              }}
              helperText={formErrors.labId?.message || ""}
              {...methods.register("labId")}
            />
          </Box>

          <Box display="flex" alignItems={"center"}>
            <Box mr={1} minWidth={120}>
              <Typography>
                {translate(intl, "userId")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <TextField
              className={clsx(classes.inputWidth500, classes.inputMargin)}
              variant={"outlined"}
              margin={"dense"}
              placeholder={translate(intl, "userId")}
              error={Boolean(formErrors.staffId)}
              InputProps={{
                inputProps: {
                  maxLength: ENTRY_LIMIT.NUMBER_FIELD
                }
              }}
              helperText={formErrors.staffId?.message || ""}
              {...methods.register("staffId")}
            />
          </Box>

          <Box display="flex">
            <Box mr={1} mt={2} minWidth={120}>
              <Typography>
                {translate(intl, "password")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <TextField
              className={clsx(classes.inputWidth500, classes.inputMargin)}
              variant={"outlined"}
              margin={"dense"}
              type="password"
              placeholder={translate(intl, "password")}
              error={Boolean(formErrors.password)}
              InputProps={{
                inputProps: {
                  maxLength: ENTRY_LIMIT.NUMBER_FIELD
                }
              }}
              helperText={formErrors.password?.message || ""}
              {...methods.register("password")}
            />
          </Box>

          <Box display="flex">
            <Box mr={1} mt={2} minWidth={120}>
              <Typography>
                {translate(intl, "common.ui.verifyPassword")}
              </Typography>
            </Box>
            <TextField
              className={clsx(classes.inputWidth500, classes.inputMargin)}
              variant={"outlined"}
              margin={"dense"}
              type="password"
              placeholder={translate(intl, "common.ui.verifyPassword")}
              error={Boolean(formErrors.verifyPassword)}
              InputProps={{
                inputProps: {
                  maxLength: ENTRY_LIMIT.NUMBER_FIELD
                }
              }}
              helperText={formErrors.verifyPassword?.message || ""}
              {...methods.register("verifyPassword")}
            />
          </Box>

          <Box display="flex">
            <Box mr={1} mt={2} minWidth={120}>
              <Typography>{translate(intl, "common.ui.referral")}</Typography>
            </Box>
            <TextField
              className={clsx(classes.inputWidth500, classes.inputMargin)}
              variant={"outlined"}
              margin={"dense"}
              type="text"
              placeholder={translate(intl, "common.ui.referral")}
              error={Boolean(formErrors.referral)}
              InputProps={{
                inputProps: {
                  maxLength: TEXT_FIELD_LENGTH.SHORT
                }
              }}
              helperText={formErrors.referral?.message}
              {...methods.register("referral")}
            />
          </Box>

          <Box display={"flex"} pb={3} alignItems={"center"}>
            <Box mr={1} minWidth={120}>
              <Typography>
                {translate(intl, "common.ui.accountType")}
              </Typography>
            </Box>
            <Switch
              labelTextOn={translate(intl, "accountType.test")}
              labelTextOff={translate(intl, "accountType.lab")}
              {...methods.register("accountType")}
            />
          </Box>
        </div>
      </Grid>
    </form>
  );
};

export default forwardRef(ApplicationForm);
