import gql from "graphql-tag";

const GET_NOTICES = gql`
  query GetNotices($input: NoticeListInput!) {
    notices(input: $input) {
      total
      items {
        id
        title
        size
        content
        publishStart
        publishEnd
        isPublished
        order
        createdAt
      }
    }
  }
`;

const GET_DASHBOARD_NOTICES = gql`
  query dashboardNotices($type: NoticeType, $skip: Int, $limit: Int) {
    dashboardNotices(type: $type, skip: $skip, limit: $limit) {
      total
      hasNext
      items {
        id
        title
        link
        date
        size
        order
        content
        createdAt
        publishStart
        publishEnd
        isPublished
      }
    }
  }
`;

const CREATE_NOTICE = gql`
  mutation CreateNotice($input: CreateNoticeInput!) {
    createNotice(input: $input) {
      id
    }
  }
`;
const UPDATE_NOTICE = gql`
  mutation UpdateNotice($input: UpdateNoticeInput!) {
    updateNotice(input: $input) {
      id
    }
  }
`;
const UPDATE_NOTICE_ORDER = gql`
  mutation UpdateNoticeOrder($input: [UpdateNoticeOrderInput]) {
    updateNoticeOrder(input: $input) {
      id
    }
  }
`;
const DELETE_NOTICE = gql`
  mutation DeleteNotice($id: ID!) {
    deleteNotice(id: $id) {
      id
    }
  }
`;

const CHANGE_PUBLISHED_STATE = gql`
  mutation changePublishedState(
    $id: ID!
    $isPublished: Boolean!
    $type: NoticeType
  ) {
    changePublishedState(id: $id, isPublished: $isPublished, type: $type) {
      code
      message
    }
  }
`;

export {
  CHANGE_PUBLISHED_STATE,
  CREATE_NOTICE,
  DELETE_NOTICE,
  GET_NOTICES,
  GET_DASHBOARD_NOTICES,
  UPDATE_NOTICE,
  UPDATE_NOTICE_ORDER
};
