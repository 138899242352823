import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  CCButton,
  CCDialogInputError,
  CCDialogSimple,
  CCTextField,
  CCTypography
} from "../";
import { useIntl } from "../../../locales/language";

const useStyles = makeStyles(theme => ({
  root: {},
  textfield: { width: 400, margin: 0 },
  btn: { marginRight: 8 },
  byte__group: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    "& > p": {
      fontSize: 14,
      color: theme.palette.normal.hover
    }
  }
}));

const CCDialogTitleAddMod = props => {
  const {
    onSave,
    onClose,
    onCancel = onClose,
    value = "",
    title,
    multiline,
    rows,
    maxLength = false,
    ...others
  } = props;
  const classes = useStyles();
  const [newValue, setNewValue] = useState(value);
  const [openError, setOpenError] = useState(false);
  const intl = useIntl();
  const handleClose = () => {
    setNewValue(value);
    onClose && onClose();
  };

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <>
      <CCDialogSimple
        title={title ? title : intl.formatMessage({ id: "ccItem" })}
        contents={
          <>
            <CCTextField
              multiline={multiline}
              rows={rows}
              variant="outlined"
              className={classes.textfield}
              value={newValue}
              onChange={e => {
                setNewValue(e.target.value);
              }}
              error={!newValue}
              InputProps={{
                inputProps: {
                  maxLength: maxLength ? maxLength : ""
                }
              }}
            />
            {Boolean(maxLength) && (
              <div className={classes.byte__group} style={{ direction: "ltr" }}>
                <CCTypography>
                  {newValue.length}/{maxLength}
                </CCTypography>
              </div>
            )}
          </>
        }
        endActions={
          <>
            <CCButton
              className={classes.btn}
              color="sub"
              onClick={() => {
                onCancel && onCancel();
              }}
            >
              {intl.formatMessage({ id: "ccCancel" })}
            </CCButton>
            <CCButton
              variant="contained"
              onClick={() => {
                if (!newValue) {
                  setOpenError(true);
                  return;
                }

                setNewValue(value);
                onSave && onSave(newValue);
              }}
            >
              {intl.formatMessage({ id: "ccSave" })}
            </CCButton>
          </>
        }
        onCloseButton={handleClose}
        // onRefreshButton={() => {
        //   setNewValue(value);
        // }}
        onClose={handleClose}
        {...others}
      />
      <CCDialogInputError
        open={openError}
        onClose={() => {
          setOpenError(false);
        }}
      />
    </>
  );
};

CCDialogTitleAddMod.propTypes = {
  /** Dialog open 유무 */
  open: PropTypes.bool,
  /** Dialog close 시키는 함수 */
  onclose: PropTypes.func,
  /** Dialog title */
  title: PropTypes.string,
  /** Dialog TextField에 작성될 문자열 */
  value: PropTypes.string,
  /** 내용 저장하는 함수 */
  onSave: PropTypes.func,
  /** 취소 버튼 클릭 시 실행되는 함수 */
  onCancel: PropTypes.func
};

CCDialogTitleAddMod.defaultProps = {
  value: ""
};

export default CCDialogTitleAddMod;
