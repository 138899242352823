import { Box } from "@material-ui/core";
import { Modal, confirm, translate } from "components";
import { useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { CCButton } from "styles/components";
import { CheckIcon } from "styles/icons";
import PopupForm from "./PopupForm";

const DialogPopupForm = props => {
  const intl = useIntl();
  const ref = useRef(null);
  const { open, selectedPopup, onClose, onSave } = props;

  const handleCancelDialog = () => {
    if (ref.current.getFormDirty()) {
      return confirm({
        type: "warning",
        title: translate(intl, "dialog.askCancel"),
        subTitle: translate(intl, "dialog.cancelWarning"),
        okTitle: translate(intl, "button.leave"),
        cancelTitle: translate(intl, "button.stay"),
        onConfirm: () => {
          onClose();
        }
      });
    }
    onClose();
  };

  const popupData = useMemo(() => {
    if (!selectedPopup) return null;
    const { publishStart, publishEnd, ...rest } = selectedPopup;
    return {
      ...rest,
      publishedTime: {
        publishStart,
        publishEnd
      }
    };
  }, [selectedPopup]);

  return (
    <Modal
      open={open}
      onClose={handleCancelDialog}
      width={960}
      title={translate(intl, "noticeTab.popup")}
      body={<PopupForm data={popupData} onSave={onSave} ref={ref} />}
      footer={
        <Box display="flex" justifyContent="end">
          <CCButton color="sub" onClick={handleCancelDialog}>
            {translate(intl, "button.cancel")}
          </CCButton>
          <CCButton
            type="submit"
            form="popup-form"
            startIcon={<CheckIcon />}
            variant="contained"
          >
            {translate(intl, "button.save")}
          </CCButton>
        </Box>
      }
    />
  );
};

export default DialogPopupForm;
