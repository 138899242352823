import { translate } from "components";
import moment from "moment";
import * as yup from "yup";

export const noticeSchema = intl =>
  yup
    .object({
      isPublished: yup
        .boolean()
        .required(translate(intl, "message.error.FIELD_REQUIRED")),
      size: yup
        .string()
        .required(translate(intl, "message.error.FIELD_REQUIRED")),
      title: yup
        .string()
        .nullable()
        .required(translate(intl, "message.error.FIELD_REQUIRED")),
      content: yup.string().nullable()
    })
    .required();

export const popupSchema = intl =>
  yup
    .object({
      isPublished: yup
        .boolean()
        .required(translate(intl, "message.error.FIELD_REQUIRED")),
      title: yup
        .string()
        .nullable()
        .required(translate(intl, "message.error.FIELD_REQUIRED")),
      content: yup.string().nullable(),
      publishedTime: yup.object({
        publishStart: yup
          .number()
          .nullable()
          .test(
            "validePublishDate",
            translate(intl, "message.error.PUBLISH_DATE"),
            (value, testContext) => {
              const { publishEnd } = testContext.parent;

              if (publishEnd && value && value > publishEnd) {
                return false;
              }
              return true;
            }
          ),
        publishEnd: yup
          .number()
          .nullable()
          .test(
            "validePublishEnd",
            translate(intl, "message.error.PUBLISH_END"),
            (value, testContext) => {
              const isPublished = testContext.from[1].value.isPublished;
              if (
                isPublished &&
                value &&
                value < moment().startOf("day").unix()
              ) {
                return false;
              }
              return true;
            }
          )
          .test(
            "validePublishDate",
            translate(intl, "message.error.PUBLISH_DATE"),
            (value, testContext) => {
              const { publishStart } = testContext.parent;

              if (publishStart && value && value < publishStart) {
                return false;
              }
              return true;
            }
          )
      })
    })
    .required();
