import { useEffect, useState } from "react";
import ImagePreviewDialog from "./Preview";
import { Target, getObject } from "utils/s3";

function Image({
  data,
  width = "120px",
  height = "auto",
  alt = "BRC",
  ...other
}) {
  const [open, setOpen] = useState(false);
  const [imageData, setImageData] = useState(data);

  useEffect(() => {
    const target = data?.target ?? Target.PHOTO;
    getObject(target, data?.id, data?.name)
      .then(res => {
        setImageData({ ...data, url: res });
      })
      .catch(err => console.error(err));
  }, [data]);

  const handleClick = event => {
    setOpen(true);
  };
  return (
    <div
      style={{
        width: "100%"
      }}
    >
      <img
        style={{
          border: "1px solid #b0bec5",
          borderRadius: "12px"
        }}
        src={imageData?.url}
        width={width}
        height={height}
        alt={alt}
        onClick={handleClick}
      />
      {imageData?.url && (
        <ImagePreviewDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          data={imageData}
        />
      )}
    </div>
  );
}
export default Image;
