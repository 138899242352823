import palette from "../default/palette";

export default {
  root: {
    color: palette.text.secondary,
    "&.MuiButtonBase-root": {
      height: 36,
      fontWeight: 600,
      borderRadius: 6,
      textTransform: "none"
    }
  },
  sizeSmall: {
    height: 28
  },
  sizeLarge: {}
};
