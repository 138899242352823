import { Dialog, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DownloadIcon } from "styles/icons";

const useStyles = makeStyles(() => ({
  dialog: {
    width: 500,
    maxWidth: 500
  },
  content: {
    padding: 0
  }
}));

const ImagePreviewDialog = props => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
    if (props.onClose) {
      props.onClose();
    }
  };
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const classes = useStyles();
  const { url, name, type } = props.data;
  return (
    <Dialog open={open} onClose={toggle} classes={{ paper: classes.dialog }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 15px",
          backgroundColor: "#ededed"
        }}
      >
        <strong>Preview</strong>
        <div
          style={{
            display: "flex"
          }}
        >
          <a href={url} target="_blank" rel="noreferrer">
            <DownloadIcon style={{ opacity: 0.3 }}></DownloadIcon>
          </a>
          <div
            style={{
              padding: "0 5px",
              cursor: "pointer"
            }}
            onClick={toggle}
          >
            &#x2715;
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000"
        }}
      >
        <img
          style={{
            display: "block",
            width: "auto",
            height: "auto",
            maxHeight: 450,
            maxWidth: 500
          }}
          src={url}
          width="100%"
          height="100%"
          alt={name || ""}
        />
      </div>
    </Dialog>
  );
};
export default ImagePreviewDialog;
