import { makeStyles } from "@material-ui/styles";
import { CCSearchTextField } from "components";
import { useEffect, useRef, useState } from "react";
import { CCButton } from "styles/components";
import { SearchIcon } from "styles/icons";

const useStyles = makeStyles(theme => ({
  input: { backgroundColor: theme.palette.common.white },
  searchtext__field: { width: 243 },
  ml8: {
    marginLeft: 8
  }
}));

const NotificationFilter = props => {
  const { filter, onSearch } = props;
  const classes = useStyles();
  const [value, setValue] = useState(filter);
  const inputRef = useRef();

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  const handleOnSearch = () => {
    setValue({ ...value, searchText: inputRef.current.value });
    onSearch({ ...value, searchText: inputRef.current.value });
  };

  return (
    <>
      {/* <DateQueryFilter value={value} onChange={val => setValue(val)} /> */}

      <CCSearchTextField
        placeholder={"Title"}
        className={classes.searchtext__field}
        label={"Title"}
        variant="outlined"
        margin="dense"
        inputRef={inputRef}
        onKeyPress={e => {
          if (e.key === "Enter") {
            handleOnSearch();
          }
        }}
        value={value.searchText}
        onChange={e => {
          setValue({ ...value, searchText: e });
        }}
        onClear={() => {
          inputRef.current.value = "";
          handleOnSearch();
        }}
      />

      {/* <CCTextField
        className={classes.searchtext__field}
        variant="outlined"
        margin="dense"
        label="Title"
        InputProps={{ classes: { root: classes.input } }}
        value={value.searchText}
        onChange={e => {
          setValue({ ...value, searchText: e.target.value });
        }}
      /> */}

      <CCButton
        variant="contained"
        color="secondary"
        startIcon={<SearchIcon />}
        className={classes.ml8}
        onClick={() => {
          onSearch && onSearch({ ...value });
        }}
      >
        Search
      </CCButton>
    </>
  );
};

export default NotificationFilter;
