import ReactDOM from "react-dom";
import DialogConfirmation from "./DialogConfirmation";
import { ContextProvider } from "context";

// Note: replacing this one with container.unmount when updating to React 18
// function reactUnmount(container) {
//   ReactDOM.unmountComponentAtNode(container);
// }

export default function confirm(config) {
  const container = document.createDocumentFragment();

  let currentConfig = { ...config, onClose, open: true };

  function render({ ...props }) {
    /**
     * Sync render blocks React event. Let's make this async.
     */
    setTimeout(() => {
      // Note: replacing this one with createRoot when updating to React 18
      ReactDOM.render(
        <ContextProvider>
          <DialogConfirmation {...props} />
        </ContextProvider>,
        container
      );
    });
  }

  function onClose() {
    currentConfig = {
      ...currentConfig,
      open: false
    };
    render(currentConfig);
  }

  function update(configUpdate) {
    if (typeof configUpdate === "function") {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate
      };
    }
    render(currentConfig);
  }

  render(currentConfig);

  return {
    destroy: onClose,
    update
  };
}
