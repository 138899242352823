import { Checkbox, FormControlLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CCTypography } from "../../components";

import React, { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  root: {},
  label: {
    color: props => props.labelColor
  },
  error__color: {
    marginLeft: props => (props.requirePosition === "right" ? 5 : 0),
    color: theme.palette.error.main,
    marginRight: props => (props.requirePosition === "left" ? 5 : 0)
  },
  color: {
    color: props => {
      if (props.color === "error") return theme.palette.error.main;
    }
  }
}));

const CCLabelCheckBox = props => {
  const {
    color,
    label,
    required,
    checked,
    labelColor,
    errorOnChange,
    requirePosition,
    error,
    onBlur,
    inputRef,
    checkboxClasses,
    ...others
  } = props;
  const classes = useStyles(props);

  useEffect(() => {
    required && errorOnChange(!checked);
  }, [errorOnChange, checked, required]);

  return (
    <FormControlLabel
      checked={checked}
      error={error}
      required={required}
      control={
        <Checkbox
          error={error}
          inputRef={inputRef}
          required={required}
          onBlur={onBlur}
          className={clsx(classes.color, checkboxClasses)}
          color={color}
        />
      }
      label={
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <CCTypography variant="h5" className={classes.label}>
            {required && requirePosition === "left" && (
              <span className={classes.error__color}>{"*"}</span>
            )}
            {label}
            {required && requirePosition === "right" && (
              <span className={classes.error__color}>{"*"}</span>
            )}
          </CCTypography>
        </Grid>
      }
      {...others}
    />
  );
};

CCLabelCheckBox.defaultProps = {
  color: "primary",
  checked: false,
  errorOnChange: () => {},
  requirePosition: "left"
};

CCLabelCheckBox.propTypes = {
  color: PropTypes.string,
  // label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  errorOnChange: PropTypes.func,
  requirePosition: PropTypes.oneOf(["left", "right"])
};

export default CCLabelCheckBox;
