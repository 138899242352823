import { useLazyQuery } from "@apollo/client";
import { Button, makeStyles } from "@material-ui/core";
import { translate } from "components";
import Excel from "exceljs";
import FileSaver from "file-saver";
import moment from "moment";
import { GET_LAB_LIST } from "queries/customer";
import { useIntl } from "react-intl";
import { ExcelIcon } from "styles/icons";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from "types/constants";
import { BASE_REGISTRATION_STATUS } from "types/registrationManage";
import { BASE_USER_STATUS } from "types/userManage";
import { LocalizeVariable } from "utils/env";
import { getPhoneNumberWithoutDialCode } from "utils/validation";

const useStyles = makeStyles(theme => ({
  excel__icon: {
    cursor: "pointer",
    color: theme.palette.icon
  }
}));

const UserDownloadExcelBtn = ({ filename, filter, disabled }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { IN_USE, DISCONTINUED, WITH_DRAW } = BASE_USER_STATUS;
  const {
    WAITING,
    APPROVED,
    REFUSED,
    WITHDRAW,
    COMPLETE_TERMINATED,
    PROCESSING_TERMINATED
  } = BASE_REGISTRATION_STATUS;
  const downloadFileName = `${filename}_${moment().format(
    DEFAULT_DATE_FORMAT
  )}.xlsx`;

  const [fetchData, { data }] = useLazyQuery(GET_LAB_LIST, {
    onCompleted: () => {
      handleDownload();
    },
    onError: () => {}
  });

  const reformatUsageStatus = data => {
    const result = {};
    result[IN_USE] = "In Use";
    result[DISCONTINUED] = "Temporary Suspended";
    result[WITH_DRAW] = "Withdraw";

    return result[data];
  };

  const reformatRegiterStatus = status => {
    const result = {};
    result[WAITING] = "Waiting";
    result[APPROVED] = "Approved";
    result[REFUSED] = "Refused";
    result[WITHDRAW] = "Withdraw";
    result[COMPLETE_TERMINATED] = "Complete Termination";
    result[PROCESSING_TERMINATED] = "Processing Terminated";

    return result[status];
  };

  const reformatAddress = place => {
    if (LocalizeVariable.isKorean) {
      return `${place?.address || ""} - ${place?.detailAddress || ""}`;
    } else {
      return place?.address || "";
    }
  };

  const reformatDate = date => {
    return moment.unix(date).format(DEFAULT_DATE_TIME_FORMAT);
  };

  const reformatPhoneNumber = phone => {
    return getPhoneNumberWithoutDialCode(phone);
  };

  const handleClickBtn = () => {
    fetchData({ variables: { input: filter } }).then(({ data }) => {
      let listData = [];
      listData = data?.labList?.items.map((itm, idx) => ({
        ...itm,
        no: idx + 1,
        registerStatus: "Approved",
        registerDate: reformatDate(itm.registrationAt),
        address: reformatAddress(itm.address),
        usageStatus: reformatUsageStatus(itm.status),
        usageDate: reformatDate(itm.statusUpdatedAt),
        labTel: reformatPhoneNumber(itm.labTel),
        cellNo: reformatPhoneNumber(itm.cellNo)
      }));
      handleDownload(listData);
    });
  };

  const handleDownload = data => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const style = {
      alignment: {
        horizontal: "center",
        vertical: "middle"
      }
    };

    worksheet.columns = [
      { header: translate(intl, "common.ui.no"), key: "no", style },
      { header: translate(intl, "common.ui.labId"), key: "labId", style },
      { header: translate(intl, "common.ui.userID"), key: "staffId", style },
      { header: translate(intl, "common.ui.labName"), key: "labName", style },
      { header: translate(intl, "common.ui.telNo"), key: "labTel", style },
      { header: translate(intl, "common.ui.eMail"), key: "email", style },
      { header: translate(intl, "common.ui.address"), key: "address", style },
      {
        header: translate(intl, "common.ui.brn"),
        key: "labBusinessRegistrationNumber",
        style
      },
      {
        header: translate(intl, "common.ui.manager"),
        key: "labManagerName",
        style
      },
      { header: translate(intl, "common.ui.cellNo"), key: "cellNo", style },
      { header: translate(intl, "common.ui.referral"), key: "referral", style },
      {
        header: translate(intl, "common.ui.registrationStatus"),
        key: "registerStatus",
        style
      },
      {
        header: translate(intl, "common.ui.registerUpdated"),
        key: "registerDate",
        style
      },
      {
        header: translate(intl, "common.ui.usageStatus"),
        key: "usageStatus",
        style
      },
      {
        header: translate(intl, "common.ui.usageUpdated"),
        key: "usageDate",
        style
      },
      {
        header: translate(intl, "common.ui.accountType"),
        key: "accountType",
        style
      }
    ];

    // add rows
    data.forEach(itm => {
      worksheet.addRow(itm);
    });

    // style header
    const header = worksheet.getRow(1);
    header.font = { bold: true };
    header.alignment = { horizontal: "center", vertical: "middle" };

    workbook.xlsx
      .writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), downloadFileName))
      .catch(err => console.log("Error writing excel export", err));
  };

  return (
    <Button
      className={classes.excel__icon}
      disabled={disabled}
      onClick={handleClickBtn}
    >
      <ExcelIcon />
    </Button>
  );
};

UserDownloadExcelBtn.defaultProp = {
  data: [],
  filename: "file"
};

UserDownloadExcelBtn.propTypes = {};

export default UserDownloadExcelBtn;
