const CloseIcon = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.964 4.608 4.608 5.964l4.748 4.748-4.748 4.748 1.356 1.357 4.748-4.748 4.748 4.748 1.357-1.357-4.748-4.748 4.748-4.748-1.357-1.356-4.748 4.748-4.748-4.748z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
