import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Box, Tab, Tabs } from "@material-ui/core";
import { CCTypography } from "styles/components";
import { RouteContext } from "context";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflowX: "hidden"
  },
  box__gradient: {
    position: "absolute",
    width: "100%",
    height: 200,
    bottom: 0,
    backgroundImage: `linear-gradient(to bottom, #00bfa5, #33ccb7)`
  },
  tab__selected: {
    backgroundColor: "white",
    color: `${theme.palette.primary.main} !important`
  },
  tab__selected__patient: {
    backgroundColor: "#00bfa5"
  },
  typography: {
    color: "inherit",
    marginTop: 8,
    textTransform: "none"
  },
  tab: {
    minWidth: 80,
    height: 80,
    color: "white",
    opacity: 1
  }
}));

const useTabStyles = makeStyles(theme => ({
  scrollButtons: {
    height: 20
  },
  indicator: {
    backgroundColor: "inherit"
  }
}));

const SidebarNav = props => {
  const { pages, location } = props;
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const { dispatchRoute } = useContext(RouteContext);

  return (
    <Box className={classes.root}>
      <Box className={classes.box__gradient} />

      <Tabs
        classes={tabClasses}
        aria-label="simple tabs example"
        orientation="vertical"
        variant="scrollable"
        scrollButtons="auto"
        style={{ height: "100%" }}
        value={0}
      >
        {pages
          .filter(page => {
            const mode = sessionStorage.getItem("mode") || "normal";

            if (page.mode !== mode) return false;
            return page.display;
          })
          .map((page, index) => {
            return (
              <Tab
                key={index}
                // value={index}
                label={
                  <>
                    {page.icon}
                    <CCTypography
                      variant="body2"
                      className={classes.typography}
                    >
                      {page.title}
                    </CCTypography>
                  </>
                }
                className={clsx({
                  [classes.tab]: true,

                  [classes.tab__selected]: location.pathname === page.path
                })}
                onClick={() => {
                  dispatchRoute({ path: page.path });
                }}
              />
            );
          })}
      </Tabs>
    </Box>
  );
};

export default SidebarNav;
