import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    position: "relative",
    marginRight: "10px",
    width: "75px",
    height: "34px",
    display: "inline-block",
    verticalAlign: "middle",
    textAlign: "left",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none"
    // "@media (max-width: 767px)": {
    //   transform: "scale(0.69)"
    // }
  },
  checkbox: {
    display: "none",
    "&:checked + label .labelText": {
      marginLeft: 0
    },
    "&:checked + label .labelSwitch": {
      right: 0
    }
  },
  label: {
    display: "block",
    overflow: "hidden",
    cursor: "pointer",
    border: "0 solid #bbb",
    borderRadius: "20px",
    margin: 0,
    "&:focus": {
      outline: "none"
    },
    "&:focus > span": {
      boxShadow: "0 0 2px 5px #2980b9"
    },
    "& > span:focus": {
      outline: "none"
    }
  },
  labelText: {
    display: "block",
    width: "200%",
    marginLeft: "-100%",
    transition: "margin 0.3s ease-in 0s",
    "&:before,&:after": {
      display: "block",
      float: "left",
      width: "50%",
      height: "34px",
      padding: 0,
      lineHeight: "34px",
      fontSize: "14px",
      color: "#fff",
      fontWeight: "bold",
      boxSizing: "border-box"
    },
    "&:before": {
      content: "attr(data-labelon)",
      paddingLeft: "10px",
      backgroundColor: "#00897b",
      color: "#fff"
    },
    "&:after": {
      content: "attr(data-labeloff)",
      paddingRight: "10px",
      backgroundColor: "#00897b",
      color: "#fff",
      textAlign: "right"
    }
  },
  labelDisabled: {
    background: "#ddd",
    cursor: "not-allowed"
  },
  labelSwitch: {
    display: "block",
    width: "24px",
    height: "24px",
    margin: "5px",
    background: "#fff",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: "40px",
    border: "0 solid #bbb",
    borderRadius: "1.2rem",
    transition: "all 0.3s ease-in 0s"
  }
});

const Switch = (
  {
    disabled,
    onChange,
    checked = false,
    id = "switch",
    labelTextOn = "Test",
    labelTextOff = "Lab",
    ...other
  },
  ref
) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        ref={ref}
        type="checkbox"
        className={classes.checkbox}
        id={id}
        {...other}
        defaultChecked={checked}
        disabled={disabled}
        onChange={onChange}
      />

      <label className={classes.label} htmlFor={id}>
        <span
          className={[
            "labelText",
            classes.labelText,
            disabled ? classes.labelDisabled : ""
          ].join(" ")}
          data-labelon={labelTextOn}
          data-labeloff={labelTextOff}
        />
        <span
          className={[
            "labelSwitch",
            classes.labelSwitch,
            disabled ? classes.labelDisabled : ""
          ].join(" ")}
        />
      </label>
    </div>
  );
};

Switch.propTypes = {
  disabled: PropTypes.bool
};

export default forwardRef(Switch);
