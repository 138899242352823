import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import {
  CVTable,
  DialogWarningConfirm,
  TypographyTooltip,
  confirm,
  translate
} from "components";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  CHANGE_PUBLISHED_STATE,
  CREATE_NOTICE,
  DELETE_NOTICE,
  GET_NOTICES,
  UPDATE_NOTICE
} from "queries/notification";
import { useEffect, useReducer } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogContent,
  CCDialogSimpleDel,
  CCIconButton,
  CCTooltip,
  CCTypography
} from "styles/components";
import { AddIcon } from "styles/icons";
import { NoticeIcon } from "theme/icons";
import { DEFAULT_DATE_FORMAT, LIMIT_PAGINATION } from "types/constants";
import { NOTICE_TYPES } from "types/notice";
import DialogPopupForm from "./DialogPopupForm";
import DialogPopupOrderSetting from "./DialogPopupOrderSetting";
import DialogPopupPreview from "./DialogPopupPreview";
import PopupFilter from "./PopupFilter";

const LIMIT = LIMIT_PAGINATION;

const useStyles = makeStyles(theme => ({
  content: {
    height: "calc(100% -30px)",
    overflow: "hidden"
  },
  container: { height: "100%" },
  item: { width: "100%" },
  container__filter: {
    width: "100%",
    padding: "4px 12px",
    margin: "0px",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  input: { backgroundColor: theme.palette.common.white },
  title__field: { width: 395, margin: 0 },
  item__table: {
    height: "calc(100% - 62px)"
  },
  table: {
    height: "100%",
    textAlign: "center"
  },
  table__body: {
    height: "calc(100vh - 284px) !important",
    width: "calc(100vw - 80px)",
    backgroundColor: "#fff",
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    },
    textAlign: "center"
  },
  table__head__row: {
    "& > div:nth-child(1), & > div:nth-child(2)": {
      width: 32
    }
  },
  table__body__row: {
    minHeight: 40
  },
  table__cell: {},
  link: {
    display: "inline-block",
    textDecorationColor: theme.palette.common.black,

    "& p": {
      width: "500px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  toolbar__divider: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    height: 36,
    marginRight: 8
  },
  ml8: {
    marginLeft: 8
  },
  announce__on: {
    color: "rgb(1, 87, 155)",
    cursor: "pointer"
  },
  announce__off: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer"
  },
  icon__button: {
    margin: 0
  }
}));

const initializer = {
  filter: {
    // start: moment().startOf("month").unix(),
    // end: moment().endOf("month").unix(),
    page: 1,
    searchText: "",
    limit: LIMIT,
    type: NOTICE_TYPES.POPUP
  },
  addDialogVisible: false,
  deleteDialogVisible: false,
  selectedPopup: null,
  maximumPublishedWarningVisible: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "filter":
      return {
        ...state,
        filter: action.target
      };
    case "setAddDialogVisible":
      return {
        ...state,
        addDialogVisible: action.target
      };
    case "setDeleteDialogVisible":
      return {
        ...state,
        deleteDialogVisible: action.target
      };
    case "setPreviewDialogVisible":
      return {
        ...state,
        previewDialogVisible: action.target
      };
    case "setOrderSettingDialogVisible":
      return {
        ...state,
        orderSettingDialogVisible: action.target
      };
    case "setSelectedPopup":
      const selectedPopup = action.target
        ? {
            ...action.target
            // date: moment.unix(action.target.date).format("YYYY/MM/DD")
          }
        : action.target;

      return {
        ...state,
        selectedPopup
      };
    case "setMaximumPublishedVisible":
      return {
        ...state,
        maximumPublishedWarningVisible: action.target
      };
    case "reset":
      return { ...initializer };
    default:
      break;
  }

  return state;
};

const TabPopup = ({ reset }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatchState] = useReducer(reducer, initializer);
  const {
    maximumPublishedWarningVisible,
    filter,
    addDialogVisible,
    deleteDialogVisible,
    previewDialogVisible,
    orderSettingDialogVisible,
    selectedPopup
  } = state;

  const { data, loading, error, refetch } = useQuery(GET_NOTICES, {
    variables: {
      input: {
        page: 1,
        limit: LIMIT,
        type: NOTICE_TYPES.POPUP
      }
    }
  });
  const totalPages = Math.ceil(data?.notices?.total / LIMIT);

  useEffect(() => {
    if (reset > 0) {
      refetchData();
    }
  }, [reset]);

  const [createNotice] = useMutation(CREATE_NOTICE, {
    onCompleted: () => {
      enqueueSnackbar(translate(intl, "message.success.save"), {
        variant: "success"
      });
      refetchData();
    },
    onError: handleError
  });

  const [updateNotice] = useMutation(UPDATE_NOTICE, {
    onCompleted: () => {
      enqueueSnackbar(translate(intl, "message.success.update"), {
        variant: "success"
      });
    },
    onError: handleError,
    refetchQueries: ["GetNotices"]
  });

  const [deleteNotice] = useMutation(DELETE_NOTICE, {
    onCompleted: () => {
      enqueueSnackbar(translate(intl, "message.success.delete"), {
        variant: "success"
      });
    },
    refetchQueries: ["GetNotices"]
  });

  const [changePublishedState] = useMutation(CHANGE_PUBLISHED_STATE, {
    onCompleted: () => {
      enqueueSnackbar(translate(intl, "message.success.update"), {
        variant: "success"
      });
    },
    onError: handleError,
    refetchQueries: ["GetNotices"]
  });

  const heads = [
    {
      key: "createdAt",
      width: "10%",
      label: translate(intl, "created"),
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {moment.unix(cellData).format(DEFAULT_DATE_FORMAT)}
          </CCTypography>
        );
      }
    },
    {
      key: "title",
      label: "Title",
      className: classes.table__cell,
      width: "58%",
      component: ({ cellData }) => {
        return (
          <TypographyTooltip variant="body1">{cellData}</TypographyTooltip>
        );
      }
    },
    {
      key: "isPublished",
      label: "Publish",
      width: "5%",
      className: classes.table__cell,
      component: ({ rowData }) => {
        const { id, isPublished, publishEnd } = rowData;

        return (
          <CCTooltip
            title={
              <CCTypography color="inherit" variant="body1">
                {isPublished
                  ? translate(intl, "hoverTitle.publish")
                  : translate(intl, "hoverTitle.unpublish")}
              </CCTypography>
            }
          >
            <CCIconButton
              className={classes.icon__button}
              size="small"
              onClick={() => {
                if (
                  !isPublished &&
                  publishEnd &&
                  publishEnd < moment().startOf("day").unix()
                ) {
                  return confirm({
                    type: "warning",
                    title: translate(intl, "message.error.PUBLISH_END"),
                    okTitle: translate(intl, "button.ok"),
                    onConfirm: () => {}
                  });
                }
                changeAnnouncement({ id, isPublished: !isPublished });
              }}
            >
              {isPublished ? (
                <NoticeIcon className={classes.announce__on} />
              ) : (
                <NoticeIcon className={classes.announce__off} />
              )}
            </CCIconButton>
          </CCTooltip>
        );
      }
    },
    {
      key: "order",
      width: "5%",
      label: translate(intl, "order"),
      className: classes.table__cell,
      component: ({ cellData, rowData }) => {
        return (
          <CCTypography variant="body1">
            {rowData.isPublished ? cellData : ""}
          </CCTypography>
        );
      }
    },
    {
      key: "publishStart",
      width: "10%",
      label: translate(intl, "publishStart"),
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {cellData ? moment.unix(cellData).format(DEFAULT_DATE_FORMAT) : ""}
          </CCTypography>
        );
      }
    },
    {
      key: "publishEnd",
      width: "10%",
      label: translate(intl, "publishEnd"),
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {cellData ? moment.unix(cellData).format(DEFAULT_DATE_FORMAT) : ""}
          </CCTypography>
        );
      }
    }
  ];

  const changeAnnouncement = ({ id, isPublished }) => {
    changePublishedState({
      variables: {
        id,
        isPublished,
        type: NOTICE_TYPES.POPUP
      }
    });
  };

  const handleOnSearch = value => {
    dispatchState({
      type: "filter",
      target: value
    });
    refetch({
      input: {
        ...filter,
        page: 1,
        searchText: value.searchText
      }
    });
  };

  const handleOnDelete = id => {
    deleteNotice({
      variables: { id }
    });
  };

  const handleOnSubmit = async value => {
    const { id, title, content, size, isPublished, publishStart, publishEnd } =
      value;

    if (!id) {
      createNotice({
        variables: {
          input: {
            ...value,
            type: NOTICE_TYPES.POPUP
          }
        }
      });
    } else {
      await updateNotice({
        variables: {
          input: {
            id,
            title,
            content,
            size,
            isPublished,
            publishStart,
            publishEnd,
            type: NOTICE_TYPES.POPUP
          }
        }
      });
    }
    dispatchState({ type: "setAddDialogVisible", target: false });
  };

  const handleOnChangePagination = (event, value) => {
    dispatchState({
      type: "filter",
      target: { ...filter, page: value }
    });

    refetch({
      input: {
        ...filter,
        page: value
      }
    });
  };

  const handleClosePublishedWarning = () => {
    dispatchState({
      type: "setMaximumPublishedVisible",
      target: false
    });
  };

  const handleOpenPublishedWarning = () => {
    dispatchState({
      type: "setMaximumPublishedVisible",
      target: true
    });
  };

  const refetchData = () => {
    dispatchState({
      type: "reset"
    });
    refetch({
      input: {
        page: 1,
        limit: LIMIT,
        type: NOTICE_TYPES.POPUP
      }
    });
  };

  function handleError({ graphQLErrors }) {
    if (!graphQLErrors || graphQLErrors.length === 0) return;
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions.code === "NOTICE03") {
        handleOpenPublishedWarning();
      }
    });
    dispatchState({
      type: "setSelectedPopup",
      target: null
    });
  }

  if (error) return <div>Some thing went wrong!</div>;

  if (loading) return <LinearProgress color="secondary" />;

  return (
    <>
      <CCDialogContent className={classes.content} noPadding>
        <Grid className={classes.container} container direction="column">
          <Grid className={classes.item} item>
            <Grid
              className={classes.container__filter}
              container
              justifyContent="space-between"
            >
              <Grid item>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <PopupFilter filter={filter} onSearch={handleOnSearch} />
                </Grid>
              </Grid>
              <Grid item>
                <CCButton
                  variant="contained"
                  color="normal"
                  onClick={() => {
                    dispatchState({
                      type: "setPreviewDialogVisible",
                      target: true
                    });
                  }}
                >
                  {translate(intl, "preview")}
                </CCButton>
                <CCButton
                  variant="contained"
                  color="normal"
                  className={classes.ml8}
                  onClick={() => {
                    dispatchState({
                      type: "setOrderSettingDialogVisible",
                      target: true
                    });
                  }}
                >
                  {translate(intl, "orderSetting")}
                </CCButton>
                <CCButton
                  variant="contained"
                  color="normal"
                  startIcon={<AddIcon />}
                  className={classes.ml8}
                  onClick={() => {
                    dispatchState({
                      type: "setAddDialogVisible",
                      target: true
                    });
                    dispatchState({
                      type: "setSelectedPopup",
                      target: null
                    });
                  }}
                >
                  {translate(intl, "add")}
                </CCButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.item__table} item>
            <CVTable
              heads={heads}
              contents={data?.notices?.items}
              className={classes.table}
              classes={{
                table__body: classes.table__body,
                table__head__row: classes.table__head__row,
                table__body__row: classes.table__body__row
              }}
              onDelClick={({ rowData }) => {
                confirm({
                  type: "warning",
                  title: translate(intl, "dialog.askDelete"),
                  subTitle: translate(intl, "dialog.deleteWarning"),
                  cancelTitle: translate(intl, "button.cancel"),
                  okTitle: translate(intl, "button.confirm"),
                  onConfirm: handleOnDelete.bind(this, rowData?.id)
                });
              }}
              onModClick={e => {
                dispatchState({
                  type: "setSelectedPopup",
                  target: e.rowData
                });
                dispatchState({
                  type: "setAddDialogVisible",
                  target: true
                });
              }}
            />

            <Box mt={1} />

            <Grid container item justifyContent="center" direction="column">
              <Pagination
                count={totalPages}
                page={filter.page}
                style={{ marginLeft: "auto", marginRight: "auto" }}
                onChange={handleOnChangePagination}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>
        </Grid>
      </CCDialogContent>

      {addDialogVisible && (
        <DialogPopupForm
          open={addDialogVisible}
          selectedPopup={selectedPopup}
          onClose={() => {
            dispatchState({
              type: "setAddDialogVisible",
              target: false
            });
            dispatchState({
              type: "setSelectedPopup",
              target: null
            });
          }}
          onSave={handleOnSubmit}
        />
      )}

      {previewDialogVisible && (
        <DialogPopupPreview
          open={previewDialogVisible}
          onClose={() =>
            dispatchState({
              type: "setPreviewDialogVisible",
              target: false
            })
          }
        />
      )}

      {orderSettingDialogVisible && (
        <DialogPopupOrderSetting
          open={orderSettingDialogVisible}
          onClose={() =>
            dispatchState({
              type: "setOrderSettingDialogVisible",
              target: false
            })
          }
        />
      )}

      <DialogWarningConfirm
        open={maximumPublishedWarningVisible}
        onAgree={handleClosePublishedWarning}
        onClose={handleClosePublishedWarning}
        agreeButtonLabel={translate(intl, "button.ok")}
        title={"Max 5 popups can be published."}
      />
    </>
  );
};

export default TabPopup;
