import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Grid } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { SidebarNav } from "./components";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.mixins.sidebar.width,
    borderRight: "none",
    backgroundColor: theme.palette.primary.main
  },
  top: {
    height: theme.mixins.topbar.height,
    backgroundColor: "#000000"
  },
  navbar: {
    height: `calc(100% - ${theme.mixins.topbar.height}px)`
  }
}));

const Sidebar = props => {
  const { open, onClose, variant, ...rest } = props;

  const classes = useStyles();

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      open={open}
      onClose={onClose}
      variant={variant}
      anchor="left"
    >
      <Grid
        container
        className={classes.top}
        justify="center"
        alignContent="center"
      >
        <ChevronLeftIcon
          style={{ color: "white", cursor: "pointer" }}
          onClick={onClose}
        />
      </Grid>


      <Grid container className={classes.navbar}>
        <SidebarNav {...rest} />
      </Grid>
    </Drawer>
  );
};

export default Sidebar;
