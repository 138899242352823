const ToastSuccessIcon = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.667 9.333c0-4.95 4.05-9 9-9s9 4.05 9 9-4.05 9-9 9-9-4.05-9-9zm4.831-.132 2.61 4.452 5.571-5.723a1.097 1.097 0 0 0 0-1.523 1.03 1.03 0 0 0-1.48 0l-3.68 3.782-1.23-2.098a1.03 1.03 0 0 0-1.438-.368 1.092 1.092 0 0 0-.353 1.478z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ToastSuccessIcon;
