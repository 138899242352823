import {
  Box,
  Tooltip as MuiTooltip,
  Zoom,
  makeStyles
} from "@material-ui/core";
import { cva } from "class-variance-authority";
import clsx from "clsx";
import * as React from "react";

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: ({ zIndex }) => zIndex
  },
  tooltip: {
    fontFamily: "SUIT Variable",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "normal",
    padding: "4px 10px",
    borderRadius: "4px",
    boxShadow: theme.shadows[1]
  },
  tooltip__placement_bottom: {
    margin: "14px 0"
  },
  tooltip__primary: {
    color: "#fff",
    backgroundColor: "#455a64"
  },
  tooltip__error: {
    color: "#cf0a0c",
    backgroundColor: "#ffe0e0",
    "&$tooltip__placement_bottom": {
      margin: "8px 0"
    }
  },
  arrow__primary: {
    color: "#191e22"
  },
  arrow__error: {
    color: "#cf0a0c"
  }
}));

const Tooltip = React.forwardRef((props, ref) => {
  const {
    intent,
    disablePortal = false,
    zIndex = 1300,
    children,
    ...any
  } = props;
  const classes = useStyles({ zIndex });
  const tooltipVariants = cva(classes.tooltip, {
    variants: {
      intent: {
        primary: classes.tooltip__primary,
        error: classes.tooltip__error
      }
    },
    defaultVariants: {
      intent: "primary"
    }
  });
  const arrowVariants = cva(classes.arrow, {
    variants: {
      intent: {
        primary: classes.arrow__primary,
        error: classes.arrow__error
      }
    },
    defaultVariants: {
      intent: "primary"
    }
  });

  return (
    <MuiTooltip
      arrow
      classes={{
        popper: classes.popper,
        tooltip: clsx(tooltipVariants({ intent })),
        arrow: clsx(arrowVariants({ intent })),
        tooltipPlacementBottom: classes.tooltip__placement_bottom
      }}
      PopperProps={{
        disablePortal,
        modifiers: {
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent"
          }
        }
      }}
      TransitionComponent={Zoom}
      {...any}
    >
      {children}
    </MuiTooltip>
  );
});
Tooltip.displayName = "Tooltip";
export default Tooltip;
