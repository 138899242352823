import { Grid, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CVTable, Image, Switch, translate } from "components";
import moment from "moment";
import { useState } from "react";
import { CCButton, CCTextField } from "styles/components";
import { makeList } from "types";
import {
  ACCOUNT_TYPE_VALUES,
  BASE_REGISTRATION_STATUS,
  DETAIL_REFERENCE_DATA,
  REGISTRATION_STATUS
} from "types/registrationManage";
import { mergeArrayByKey } from "utils/array";
import { isExpiredWithdrawTime } from "../../helper";

import { useIntl } from "react-intl";
import { DEFAULT_DATE_TIME_FORMAT } from "types/constants";

const useStyles = makeStyles(theme => ({
  root: {},
  table__body__row: {
    display: "flex",
    alignItems: "center"
  },
  table__cell: {
    padding: "6px 4px",
    width: "500px",
    backgroundColor: "white"
    // borderBottom: "0.2px solid #e7e9eb"
  },
  status__selection: {
    minWidth: "150px"
  },
  registration__field: {
    padding: "5px 6px",
    borderBottom: "1px solid #e7e9eb"
  }
}));

const reformatDetailLabData = labInformation => {
  const arr2 = [];

  for (const key in labInformation) {
    arr2.push({
      key,
      value: labInformation[key]
    });
  }
  const resultMerged = mergeArrayByKey(DETAIL_REFERENCE_DATA, arr2, "key");
  return resultMerged;
};

const makeListUpdateBaseCurrentStatus = ({
  registrationStatusList = [],
  currentStatus = "1",
  withdrawAt
}) => {
  const {
    WAITING,
    APPROVED,
    REFUSED,
    WITHDRAW,
    CANCEL_WITHDRAW,
    PROCESSING_TERMINATED,
    COMPLETE_TERMINATED,
    CANCEL_TERMINATED
  } = BASE_REGISTRATION_STATUS;

  let statusList = [];
  if (currentStatus === WAITING) {
    statusList = registrationStatusList.filter(o =>
      [WAITING, APPROVED, REFUSED].includes(o.key)
    );
  }

  if (currentStatus === REFUSED) {
    statusList = registrationStatusList.filter(o => [REFUSED].includes(o.key));
  }

  if (currentStatus === APPROVED) {
    statusList = registrationStatusList.filter(o => [APPROVED].includes(o.key));
  }

  if (currentStatus === PROCESSING_TERMINATED) {
    statusList = registrationStatusList.filter(o =>
      [CANCEL_TERMINATED, PROCESSING_TERMINATED, COMPLETE_TERMINATED].includes(
        o.key
      )
    );
  }

  if (currentStatus === COMPLETE_TERMINATED) {
    statusList = registrationStatusList.filter(o =>
      [COMPLETE_TERMINATED].includes(o.key)
    );
  }

  if (currentStatus === WITHDRAW) {
    if (isExpiredWithdrawTime(withdrawAt)) {
      statusList = registrationStatusList.filter(o =>
        [WITHDRAW].includes(o.key)
      );
    } else {
      statusList = registrationStatusList.filter(o =>
        [WITHDRAW].includes(o.key)
      );
    }
  }

  return statusList;
};

const RegistrationDetail = props => {
  const { data, onUpdate, onProcessingTerminate, onChangeAccountType } = props;
  const { statusUpdatedAt, updatedAt, currentStatus, withdrawAt } = data;
  const contents = reformatDetailLabData(data);

  const intl = useIntl();
  const classes = useStyles();
  // let registrationStatusList = makeList(REGISTRATION_STATUS);

  let registrationStatusList = makeListUpdateBaseCurrentStatus({
    registrationStatusList: makeList(REGISTRATION_STATUS),
    currentStatus: String(currentStatus),
    withdrawAt
  });

  const {
    WAITING,
    APPROVED,
    PROCESSING_TERMINATED,
    COMPLETE_TERMINATED,
    REFUSED
  } = BASE_REGISTRATION_STATUS;

  const [status, setStatus] = useState(data.status);

  const heads = [
    {
      key: "key",
      className: classes.table__cell,
      component: ({ rowData }) => {
        return <Typography variant="h5">{rowData.label}</Typography>;
      }
    },
    {
      key: "value",
      // label: "Lab Name",
      className: classes.table__cell,
      component: ({ rowData }) => {
        const { key, value } = rowData;
        if (key === "birthDate") {
          return moment.unix(value).format("L");
        }
        if (key === "businessRegistration") {
          if (value?.url) return <Image data={value} />;
          return <div />;
        }
        return value;
      }
    }
  ];

  /**
   * Handle build status to display
   * @returns { String }
   */
  const makeDisplayStatus = () => {
    const status = data.status;
    const currentStatus = data.currentStatus;
    /**
     * Do not want current status hide when change to PROCESSING_TERMINATED status when current status is approve
     */

    if (currentStatus === APPROVED && status === PROCESSING_TERMINATED) {
      return APPROVED;
    }

    return status;
  };

  return (
    <Grid container className={classes.root} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <CVTable
          classes={classes}
          heads={heads}
          contents={contents}
          del={({ rowIndex }) => {}}
          mod={() => {
            console.log("mod");
          }}
          add={() => {
            console.log("add");
          }}
          sorting={true}
        />
      </Grid>

      {/* <Paper className={classes.paper}>xs=12</Paper> */}

      <Grid
        container
        alignItems="center"
        className={classes.registration__field}
        style={{ padding: "8px 6px" }}
      >
        <Grid item xs={6}>
          <Typography variant="h5">
            {translate(intl, "common.ui.referral")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div>{data?.referral}</div>
        </Grid>
      </Grid>

      <Grid
        container
        item
        justify="flex-start"
        alignItems="center"
        className={classes.registration__field}
      >
        <Grid item xs={6}>
          <Typography variant="h5">Registration Status</Typography>
        </Grid>
        <Grid item container justify="flex-start" alignItems="center" xs={6}>
          <CCTextField
            value={makeDisplayStatus()}
            label={""}
            disabled={[APPROVED, REFUSED, COMPLETE_TERMINATED].includes(
              String(currentStatus)
            )}
            select
            variant="outlined"
            margin="dense"
            className={classes.status__selection}
            onChange={e => {
              setStatus(e.target.value);
              onUpdate({ ...data, status: e.target.value });
            }}
          >
            {registrationStatusList.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </CCTextField>
          ({moment.unix(statusUpdatedAt).format(DEFAULT_DATE_TIME_FORMAT)})
          &nbsp;
          {currentStatus === APPROVED && (
            <CCButton
              variant={"contained"}
              size={"small"}
              color={"normal"}
              onClick={() => {
                onUpdate({ ...data, status: PROCESSING_TERMINATED });
              }}
              // onClick={onUpdate({ ...data, status: PROCESSING_TERMINATED })}
            >
              Processing Terminated
            </CCButton>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        className={classes.registration__field}
      >
        <Grid item xs={6}>
          <Typography variant="h5">
            {translate(intl, "common.ui.accountType")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch
            // disabled
            checked={data.accountType === ACCOUNT_TYPE_VALUES.TEST.value}
            labelTextOn={translate(intl, "accountType.test")}
            labelTextOff={translate(intl, "accountType.lab")}
            onChange={event => {
              onChangeAccountType({
                ...data,
                accountType: event.target.checked
                  ? ACCOUNT_TYPE_VALUES.TEST.value
                  : ACCOUNT_TYPE_VALUES.LAB.value
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegistrationDetail;
