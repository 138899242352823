import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CCSearchTextField, translate } from "components";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCTextField } from "styles/components";
import { SearchIcon } from "styles/icons";
import { makeList } from "types";
import { ACCOUNT_TYPE_VALUES } from "types/registrationManage";
import { USER_STATUS_FILTER } from "types/userManage";

const useStyles = makeStyles(theme => ({
  input: { backgroundColor: theme.palette.common.white },
  searchtext__field: {
    width: 243
    // marginLeft: 32
  },
  ml8: {
    marginLeft: 8
  },
  selection: {
    width: 142
  }
}));

const UserFilter = props => {
  const { filter, onSearch } = props;

  // console.log("RegistrationFilter.props", props)
  const classes = useStyles();
  const [value, setValue] = useState(filter);

  const intl = useIntl();

  const userStatusList = makeList(USER_STATUS_FILTER);
  const accountTypeList = makeList(ACCOUNT_TYPE_VALUES);

  const inputRef = useRef();

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  useEffect(() => {
    handleOnSearch();
  }, [value.accountType, value.status]);

  const handleOnSearch = () => {
    setValue({ ...value, searchText: inputRef.current.value });
    onSearch({ ...value, searchText: inputRef.current.value });
  };
  return (
    <>
      {/* <DateQueryFilter value={value} onChange={val => setValue(val)} /> */}

      <CCSearchTextField
        // placeholder={"Lab, Manager, Cell No., Tel No."}
        label={"Lab ID, Name, Tel No."}
        className={classes.searchtext__field}
        variant="outlined"
        margin="dense"
        inputRef={inputRef}
        value={value.searchText}
        onKeyPress={e => {
          if (e.key === "Enter") {
            // handleSearchTextClick();
            // onSearch({...value, searchText: inputRef.current.value});
            handleOnSearch();
          }
        }}
        onChange={e => {
          // setValue({ ...value, searchText: e });
          setValue(prevState => ({ ...prevState, searchText: e }));
        }}
        onClear={() => {
          inputRef.current.value = "";
          // setValue({ ...value, searchText: inputRef.current.value });
          handleOnSearch();
          // handleSearchTextClick();
        }}
      />

      {/* <CCTextField
        className={classes.searchtext__field}
        variant="outlined"
        margin="dense"
        label="Lab, Manager, Cell No., Tel No."
        InputProps={{ classes: { root: classes.input } }}
        value={value.searchText}
        onChange={e => {
          setValue({ ...value, searchText: e.target.value });
        }}
      /> */}

      <CCTextField
        value={value.status}
        label={"Usage Status"}
        select
        variant="outlined"
        margin="dense"
        className={classes.selection}
        onChange={e => setValue({ ...value, status: e.target.value })}
      >
        {userStatusList.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </CCTextField>

      <CCTextField
        value={value.accountType}
        label={translate(intl, "common.ui.accountType")}
        select
        variant="outlined"
        margin="dense"
        className={classes.selection}
        onChange={e => setValue({ ...value, accountType: e.target.value })}
      >
        {accountTypeList.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </CCTextField>

      <CCButton
        variant="contained"
        color="secondary"
        startIcon={<SearchIcon />}
        className={classes.ml8}
        onClick={() => {
          onSearch && onSearch({ ...value });
        }}
      >
        Search
      </CCButton>
    </>
  );
};

export default UserFilter;
