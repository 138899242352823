import { CKEditor } from "@ckeditor/ckeditor5-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Editor from "ckeditor5-custom-build";
import { Tooltip, translate } from "components";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { CCTextField, CCTooltip, CCTypography } from "styles/components";
import { InfoIcon } from "styles/icons";
import { theme } from "theme";
import { makeList } from "types";
import { ENTRY_LIMIT } from "types/constants";
import { DIALOG_SIZES } from "types/notice";
import editorConfiguration from "utils/ckeditor";
import { noticeSchema } from "utils/validations/notice";

const useStyles = makeStyles(() => ({
  root: {
    padding: 12
  }
}));

const NotificationForm = ({ data, onSave }, ref) => {
  const intl = useIntl();
  const classes = useStyles();
  const dialogSizes = makeList(DIALOG_SIZES);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(noticeSchema(intl)),
    defaultValues: {
      isPublished: true,
      size: DIALOG_SIZES.SMALL.value,
      title: "",
      content: ""
    }
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        getFormDirty: () => isDirty
      };
    },
    [isDirty]
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    reset(data);
  }, [data]);

  const onSubmit = data => {
    onSave(data);
  };

  return (
    <form id="notice-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" className={classes.root}>
        <Box display="flex" alignItems="center">
          <Box width={200}>
            <Controller
              name="isPublished"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Tooltip
                  intent="error"
                  title={error?.message}
                  open={Boolean(error)}
                >
                  <CCTextField
                    select
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    error={error}
                    label={
                      <span>
                        {translate(intl, "publish")}{" "}
                        <span className="asterisk">*</span>
                      </span>
                    }
                    {...field}
                  >
                    <MenuItem key="true" value={true}>
                      {translate(intl, "publish")}
                    </MenuItem>
                    <MenuItem key="false" value={false}>
                      {translate(intl, "unpublish")}
                    </MenuItem>
                  </CCTextField>
                </Tooltip>
              )}
            />
          </Box>
          <Box width={140}>
            <Controller
              name="size"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Tooltip
                  intent="error"
                  title={error?.message}
                  open={Boolean(error)}
                >
                  <CCTextField
                    select
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    error={error}
                    label={
                      <span>
                        {translate(intl, "dialogSize")}{" "}
                        <span className="asterisk">*</span>
                      </span>
                    }
                    {...field}
                  >
                    {dialogSizes.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </CCTextField>
                </Tooltip>
              )}
            />
          </Box>
          <CCTooltip
            arrow
            title={
              <CCTypography
                color="inherit"
                variant="body1"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {Object.values(DIALOG_SIZES)
                  .map(
                    itm =>
                      `${translate(intl, itm.localeKey)}: ${itm.width} x ${
                        itm.height
                      }`
                  )
                  .join("\n")}
              </CCTypography>
            }
          >
            <Box>
              <InfoIcon style={{ color: theme.palette.text.label }} />
            </Box>
          </CCTooltip>
        </Box>
        <Controller
          name="title"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Tooltip
              intent="error"
              title={error?.message}
              open={Boolean(error)}
            >
              <CCTextField
                variant="outlined"
                margin="dense"
                fullWidth
                error={error}
                label={
                  <span>
                    {translate(intl, "title")}{" "}
                    <span className="asterisk">*</span>
                  </span>
                }
                inputProps={{
                  maxLength: ENTRY_LIMIT.TEXT_FIELD.MIDDLE
                }}
                {...field}
                onBlur={e => field.onChange(e.target.value.trim())}
              />
            </Tooltip>
          )}
        />
        <Box m="8px" className="ck-content">
          <Controller
            name="content"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CKEditor
                editor={Editor}
                config={editorConfiguration}
                data={value}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  onChange(data);
                }}
              />
            )}
          />
        </Box>
      </Grid>
    </form>
  );
};

export default forwardRef(NotificationForm);
