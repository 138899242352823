import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import { default as PropTypes, default as propTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CCIconButton from "styles/src/components/CCIconButton";
import CCTextField from "styles/src/components/CCTextField";
import {
  DateIcon,
  MoonborderIcon,
  MoonIcon
} from "styles/src/themes/common/icons";
import CCFlattenDatePicker from "./CCFlattenDatePicker";
import { DEFAULT_DATE_FORMAT } from "types/constants";
import { LocalizeVariable } from "utils/env";

const useStyle = makeStyles(
  theme => ({
    root: {},
    dateButton: {
      color: theme.palette.icon
    },
    selected__button: {
      color: theme.palette.select.main
    },
    padding__right: { paddingRight: 8 },
    disabled: {
      color: theme.palette.disabled
    }
  }),
  { name: "CCFlattenDateField" }
);
const CCFlattenDateField = props => {
  const {
    value,
    onChange = () => {},
    lunar,
    lunarOnChange,
    label,
    disabled,
    dateIcon,
    dateIconTooltip,
    components,
    checkedList,
    onChangeHeader,
    onPickerChange = () => {},
    onClickIconPermission,
    mode,
    needWeek,
    preserveCurrentTime,
    defaultValue,
    onBlur,
    ...others
  } = props;
  const intl = useIntl();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [momentValue, setMomentValue] = useState(value);
  const [textValue, setTextValue] = useState(
    value === false
      ? ""
      : mode === "day"
      ? value.clone().format(DEFAULT_DATE_FORMAT)
      : `${value.begin.clone().format("YYYY/MM")} ${
          value.begin.clone().week() -
          value.begin.clone().startOf("month").week() +
          1
        }주`
  );
  const classes = useStyle();

  useEffect(() => {
    if (!value) {
      setTextValue("");
      return;
    }
    // console.log("momentValue", momentValue.toString());
    if (mode === "day") {
      setMomentValue(prevState => {
        if (prevState === false) {
          return value;
        } else if (value.format("L") !== prevState.format("L")) {
          return value;
        } else {
          return prevState;
        }
      });
      setTextValue(value.clone().format(DEFAULT_DATE_FORMAT));
    } else {
      setMomentValue(prevState => {
        if (prevState === false) {
          return value;
        } else if (value.begin.format("L") !== prevState.begin.format("L")) {
          return value;
        } else {
          return prevState;
        }
      });
      setTextValue(
        `${value.begin.clone().format("YYYY/MM")} ${
          value.begin.clone().week() -
          value.begin.clone().startOf("month").week() +
          1
        }주`
      );
    }
  }, [value, momentValue, mode]);

  const Icon = dateIcon ? dateIcon : DateIcon;
  const tooltip = dateIconTooltip ? { tooltip: dateIconTooltip } : {};

  return (
    <>
      {pickerOpen && (
        <CCFlattenDatePicker
          open={pickerOpen}
          value={momentValue === false ? moment() : momentValue}
          onChange={e => {
            onChange(e);
            setPickerOpen(false);
            onPickerChange(false);
          }}
          onClose={() => {
            setPickerOpen(false);
            onPickerChange(false);
          }}
          components={components}
          checkedList={checkedList}
          onChangeHeader={onChangeHeader}
          mode={mode}
          needWeek={needWeek}
          preserveCurrentTime={preserveCurrentTime}
        />
      )}
      <CCTextField
        {...others}
        disabled={disabled}
        variant={"outlined"}
        margin={"dense"}
        label={label}
        value={textValue}
        onChange={e =>
          setTextValue(LocalizeVariable.formatInputValue(e.target.value))
        }
        onBlur={() => {
          onChange(moment(textValue, DEFAULT_DATE_FORMAT));
          onBlur && onBlur();
          // setTextValue(
          //   momentValue === false ? "" : momentValue.format("YYYY/MM/DD")
          // );
        }}
        InputProps={{
          classes: { adornedEnd: classes.padding__right },
          readOnly: false,
          endAdornment: (
            <InputAdornment position={"end"}>
              <CCIconButton
                size={"small"}
                onClick={() => {
                  if (onClickIconPermission) {
                    onClickIconPermission();
                    return;
                  }
                  if (disabled) return;
                  setPickerOpen(true);
                  onPickerChange(true);
                }}
                {...tooltip}
              >
                <Icon
                  className={clsx(classes.dateButton, {
                    [classes.disabled]: disabled
                  })}
                />
              </CCIconButton>
              {lunarOnChange && (
                <CCIconButton
                  size={"small"}
                  onClick={() => {
                    if (onClickIconPermission) {
                      onClickIconPermission();
                      return;
                    }
                    if (disabled) return;
                    lunarOnChange(!lunar);
                  }}
                  tooltip={
                    lunar
                      ? intl.formatMessage({
                          id: "ccLunarYear",
                          defaultMessage: "음력"
                        })
                      : intl.formatMessage({
                          id: "ccSunYear",
                          defaultMessage: "양력"
                        })
                  }
                  className={clsx({
                    [classes.selected__button]: lunar,
                    [classes.disabled]: disabled
                  })}
                >
                  {lunar ? <MoonIcon /> : <MoonborderIcon />}
                </CCIconButton>
              )}
            </InputAdornment>
          )
        }}
      />
    </>
  );
};
CCFlattenDateField.propTypes = {
  value: propTypes.oneOfType([
    propTypes.instanceOf(moment),
    propTypes.bool,
    propTypes.shape({
      begin: propTypes.instanceOf(moment),
      end: propTypes.instanceOf(moment)
    })
  ]).isRequired,
  onChange: propTypes.func.isRequired,
  lunar: propTypes.bool,
  lunarOnChange: propTypes.func,
  label: propTypes.string,
  mode: PropTypes.oneOf(["day", "week"]),
  // readOnly: propTypes.bool
  needWeek: PropTypes.bool,
  preserveCurrentTime: propTypes.bool
};
CCFlattenDateField.defaultProps = {
  // readOnly: true
  mode: "day",
  label: "날짜",
  needWeek: false,
  preserveCurrentTime: false
};
export default CCFlattenDateField;
