import gql from "graphql-tag";

const GET_LAB_APPLICATION_REQ_STATS = gql`
  query GetLabApplicationRequestStatistics {
    labApplicationRequestStatistics {
      total
      totalNewApplication
      totalAcceptedApplication
      totalRefusedApplication
      totalWithDrawApplication
      totalProcessingTerminationApplication
      totalCompleteTerminationApplication
    }
  }
`;
const GET_LAB_APPLICATION_REQ_LIST = gql`
  query GetLabApplicationRequestList($input: LabApplicationRequestListInput!) {
    labApplicationRequestList(input: $input) {
      total
      hasNext
      hasPrev
      items {
        id
        status
        labName
        managerName
        labManagerName
        labId
        managerCellNumber
        labTel
        labTelNumber
        email
        address
        createdAt
        updatedAt
        staffId
        acceptedAt
        refusedAt
        statusUpdatedAt
        birthDate
        withdrawAt
        processingTerminatedAt
        terminatedAt
        registrationAt
        cellNo
        phoneNo
        labBusinessRegistrationNumber
        referral
        location {
          address
          placeId
          detailAddress
          coordinate {
            latitude
            longitude
          }
        }
        accountType
        businessRegistration {
          id
          name
          type
          url
          target
        }
      }
    }
  }
`;
const UPDATE_LAB_APPLICATION_REQ = gql`
  mutation UpdateLabApplicationRequest(
    $input: UpdateLabApplicationRequestInput!
  ) {
    updateLabApplicationRequest(input: $input)
  }
`;

const UPDATE_ACCOUNT_TYPE_LAB_REQUEST = gql`
  mutation updateLabRequestAccountType(
    $input: UpdateLabApplicationRequestInput!
  ) {
    updateLabRequestAccountType(input: $input)
  }
`;

const REGISTER_NEW_LAB = gql`
  mutation createLabApplicationRequest($input: CreateLabRequestInput!) {
    createLabApplicationRequest(input: $input) {
      id
      status
      labName
      managerName
      managerCellNumber
      labId
      labTelNumber
      email
      address
      createdAt
      updatedAt
    }
  }
`;

export {
  GET_LAB_APPLICATION_REQ_STATS,
  GET_LAB_APPLICATION_REQ_LIST,
  UPDATE_LAB_APPLICATION_REQ,
  REGISTER_NEW_LAB,
  UPDATE_ACCOUNT_TYPE_LAB_REQUEST
};
