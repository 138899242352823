const ArrowLeftIcon = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m14 3-8 7 8 7" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default ArrowLeftIcon;
