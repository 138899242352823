import React from "react";
import NoticeManagement from "./NoticeManagement";
import DashBoard from "./DashBoard";
import RegistrationManage from "./RegistrationManage";
import UserManagement from "./UserManagement";
import PasswordManagement from "./PasswordManagement";
import Settings from "./Settings";
import { Main as MainLayout } from "layouts";
import { ChartIcon, FamilyIcon, NotificationsIcon, CategoryIcon, LockIcon, SettingIcon } from "styles/icons";
import { CAPITALIZE_TYPE, FormattedMessage } from "utils/language";

const pages = [
  {
    path: "/dashboard",
    title: (
      <FormattedMessage
        id={"dashboard"}
        capitalizeType={CAPITALIZE_TYPE.FIRST_WORD}
      />
    ),
    icon: <CategoryIcon />,
    component: DashBoard,
    layout: MainLayout,
    display: true
  },
  {
    path: "/registrationManagement",
    title: (
      <FormattedMessage
        id={"registrationManagement"}
        capitalizeType={CAPITALIZE_TYPE.FIRST_WORD}
      />
    ),
    icon: <ChartIcon />,
    component: RegistrationManage,
    layout: MainLayout,
    display: true
  },
  {
    path: "/userManagement",
    title: (
      <FormattedMessage
        id={"userManagement"}
        capitalizeType={CAPITALIZE_TYPE.FIRST_WORD}
      />
    ),
    icon: <FamilyIcon />,
    component: UserManagement,
    layout: MainLayout,
    display: true
  },
  {
    path: "/passwordManagement",
    title: (
      <FormattedMessage
        id={"resetPassword"}
        capitalizeType={CAPITALIZE_TYPE.FIRST_WORD}
      />
    ),
    icon: <LockIcon />,
    component: PasswordManagement,
    layout: MainLayout,
    display: true
  },
  {
    path: "/noticeManagement",
    title: (
      <FormattedMessage
        id={"notificationManagement"}
        capitalizeType={CAPITALIZE_TYPE.FIRST_WORD}
      />
    ),
    icon: <NotificationsIcon />,
    component: NoticeManagement,
    layout: MainLayout,
    display: true
  },
  {
    path: "/settings",
    title: (
      <FormattedMessage
        id={"settings"}
        capitalizeType={CAPITALIZE_TYPE.FIRST_WORD}
      />
    ),
    icon: <SettingIcon />,
    component: Settings,
    layout: MainLayout,
    display: true
  },
  // {
  //   path: "/applicationManagement",
  //   title: (
  //     <FormattedMessage
  //       id={"applicationManagement"}
  //       capitalizeType={CAPITALIZE_TYPE.FIRST_WORD}
  //     />
  //   ),
  //   icon: <ChartIcon />,
  //   component: ApplicationManagement,
  //   layout: MainLayout,
  //   display: true
  // },
  // {
  //   path: "/customerManagement",
  //   title: (
  //     <FormattedMessage
  //       id={"customerManagement"}
  //       capitalizeType={CAPITALIZE_TYPE.FIRST_WORD}
  //     />
  //   ),
  //   icon: <FamilyIcon />,
  //   component: CustomerManagement,
  //   layout: MainLayout,
  //   display: true
  // },

].map(el => {
  el.mode = "normal";
  return el;
});

export { pages };
