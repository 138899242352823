const REGISTRATION_STATUS = {
  WAITING: {
    key: "0",
    value: "0",
    localeKey: "registration.status.waiting" // ~ APPLIED
  },
  APPROVED: {
    value: "1",
    key: "1",
    localeKey: "registration.status.approved" // ~ ACCEPTED
  },
  REFUSED: {
    key: "2",
    value: "2",
    localeKey: "registration.status.refused" // ~ REFUSED
  },
  WITHDRAW: {
    key: "3",
    value: "3",
    localeKey: "registration.status.withdraw" // ~ NEW STATUS
  },
  CANCEL_TERMINATED: {
    key: "4",
    value: "4",
    localeKey: "registration.status.cancelTerminated"
  },

  PROCESSING_TERMINATED: {
    key: "5",
    value: "5",
    localeKey: "registration.status.processingTerminated" // ~ when status is withdraw when enough condition will became approve
  },
  COMPLETE_TERMINATED: {
    key: "6",
    value: "6",
    localeKey: "registration.status.completeTerminated" // ~ when status is withdraw when enough condition will became approve
  }
};

const REGISTRATION_FILTER_STATUS = {
  ALL: {
    value: "9",
    localeKey: "registration.status.all"
  },
  WAITING: {
    value: "0",
    localeKey: "registration.status.waiting"
  },
  APPROVED: {
    value: "1",
    localeKey: "registration.status.approved"
  },
  REFUSED: {
    value: "2",
    localeKey: "registration.status.refused"
  },
  PROCESSING_TERMINATION: {
    value: "5",
    localeKey: "registration.status.processingTerminated"
  },
  COMPLETE_TERMINATION: {
    value: "6",
    localeKey: "registration.status.completeTerminated"
  }
  // WITHDRAW: {
  //   value: "3",
  //   localeKey: "registration.status.withdraw"
  // }
};

const ACCOUNT_TYPE_VALUES = {
  ALL: {
    value: "all",
    localeKey: "accountType.all"
  },
  LAB: {
    value: "lab",
    localeKey: "accountType.lab"
  },
  TEST: {
    value: "test",
    localeKey: "accountType.test"
  }
};

const DETAIL_REFERENCE_DATA = [
  {
    key: "labId",
    label: "Lab Id",
    order: 1
  },
  {
    key: "staffId",
    label: "User Id",
    order: 2
  },
  {
    key: "labName",
    label: "Lab Name",
    order: 3
  },
  {
    key: "labTel",
    label: "Tel No.",
    order: 4
  },
  {
    key: "businessRegistration",
    label: "BRC",
    order: 5
  },
  // {
  //   key: "email",
  //   label: "E-mail",
  //   order: 5
  // },
  {
    key: "labBusinessRegistrationNumber",
    label: "Business Registration Number",
    order: 6
  },
  {
    key: "labManagerName",
    label: "Manager Name",
    order: 7
  },
  {
    key: "cellNo",
    label: "Cell no.",
    order: 8
  }
  // {
  //   key: "birthDate",
  //   label: "Birth Date",
  //   order: 9
  // }
  // {
  //   key: 'status',
  //   order: 10,
  // },
];

const BASE_REGISTRATION_STATUS = {
  WAITING: "0",
  APPROVED: "1",
  REFUSED: "2",
  WITHDRAW: "3",
  CANCEL_TERMINATED: "4",
  PROCESSING_TERMINATED: "5",
  COMPLETE_TERMINATED: "6"
};

export {
  REGISTRATION_STATUS,
  REGISTRATION_FILTER_STATUS,
  DETAIL_REFERENCE_DATA,
  BASE_REGISTRATION_STATUS,
  ACCOUNT_TYPE_VALUES
};
