import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import {
  CVButton,
  CVLabelCheckBox,
  CVTextField,
  CVTextFieldDualType,
  Loading,
  enqueueToast,
  translate
} from "components";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogActions,
  CCDialogContent,
  CCDialogTitle,
  CCPaper,
  CCTextField
} from "styles/components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CheckIcon, InvisibleIcon, SMSIcon, VisibleIcon } from "styles/icons";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SMTP_SETTING, SEND_EMAIL_TEST } from "queries/setting";
import { useSnackbar } from "notistack";
import { UPDATE_SMTP_SETTING } from "queries/smtpSetting";
import { ENTRY_LIMIT } from "types/constants";
import { stringToSlug } from "utils/utils";

const useStyle = makeStyles(
  theme => ({
    root: {
      width: 624,
      position: "relative"
    },
    help__button: {
      color: theme.palette.icon
    },
    content: {
      overflowY: "hidden"
    },
    subText: {
      color: theme.palette.sub.main,
      paddingLeft: 5
    },
    input: {
      width: 268
    },
    menuList: { padding: 0 },
    menuItem: {
      minWidth: 110,
      padding: `4px 8px`
    },
    title: { alignSelf: "center", marginLeft: 4 }
  }),
  { name: "SMTPSetting" }
);

const SMTP_SERVER = [
  {
    label: "google.com",
    value: "smtp.gmail.com",
    port: "587",
    ssl: true
  },
  {
    label: "naver.com",
    value: "smtp.naver.com",
    port: "587",
    ssl: true
  }
];

const smtpSchema = yup
  .object({
    smtpUrl: yup.string().required("This field is required."),
    ssl: yup.boolean(),
    port: yup.string().required("This field is required."),
    sender: yup
      .string()
      .required("This field is required.")
      .email("Please use a valid email address")
      .matches(/^[a-zA-Z0-9.@]{1,320}$/, "Please use a valid email address"),
    password: yup.string().required("This field is required."),
    direct: yup.boolean()
  })
  .required();

export default function SMTPSetting() {
  const classes = useStyle();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const { control, watch, setValue, reset, handleSubmit } = useForm({
    resolver: yupResolver(smtpSchema),
    mode: "onChange",
    defaultValues: {
      smtpUrl: "",
      ssl: true,
      direct: false,
      port: "",
      sender: "",
      password: ""
    }
  });

  const category = "adminSmtpSetting";

  const { data: smtpData, loading } = useQuery(GET_SMTP_SETTING, {
    variables: {
      labId: "admin",
      isAdminConfig: true,
      category
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data?.getConfig?.smtpSetting) {
        reset({
          sender: data?.getConfig?.smtpSetting?.sender,
          password: data?.getConfig?.smtpSetting?.password,
          smtpUrl: data?.getConfig?.smtpSetting?.smtpUrl,
          port: data?.getConfig?.smtpSetting?.port,
          ssl: data?.getConfig?.smtpSetting?.ssl,
          direct: SMTP_SERVER.map(item => item.value).includes(
            data?.getConfig?.smtpSetting?.from
          )
        });
      }
    }
  });

  const [sendEmailTest, { loading: isSending }] = useMutation(SEND_EMAIL_TEST, {
    onCompleted: res => {
      if (
        res.sendEmailTest &&
        // eslint-disable-next-line eqeqeq
        res.sendEmailTest.code == 200
      ) {
        enqueueToast(
          enqueueSnackbar,
          intl.formatMessage(
            { id: "settings.smtp.toastSentTestMail" },
            { email: watch("sender") }
          )
        );
      } else {
        enqueueToast(
          enqueueSnackbar,
          intl.formatMessage(
            { id: "settings.smtp.toastFailedSentTestMail" },
            { email: watch("sender") }
          )
        );
      }
    },
    onError: () => {
      enqueueToast(
        enqueueSnackbar,
        intl.formatMessage(
          { id: "settings.smtp.toastFailedSentTestMail" },
          { email: watch("sender") }
        )
      );
    },
    refetchQueries: [
      {
        query: GET_SMTP_SETTING,
        variables: {
          labId: "admin",
          isAdminConfig: true,
          category
        }
      }
    ]
  });

  const handleSendEmail = async () => {
    const language = localStorage.getItem("lang");
    sendEmailTest({
      variables: {
        from: watch("sender"),
        password: watch("password"),
        host: watch("smtpUrl"),
        port: Number(watch("port")),
        ssl: Boolean(watch("ssl")),
        language
      }
    });
  };

  const onReset = () => {
    reset({
      sender: smtpData?.getConfig?.smtpSetting?.sender,
      password: smtpData?.getConfig?.smtpSetting?.password,
      smtpUrl: smtpData?.getConfig?.smtpSetting?.smtpUrl,
      port: smtpData?.getConfig?.smtpSetting?.port,
      ssl: smtpData?.getConfig?.smtpSetting?.ssl,
      direct: SMTP_SERVER.map(item => item.value).includes(
        smtpData?.getConfig?.smtpSetting?.from
      )
    });
  };

  const [createSmtpSetting] = useMutation(UPDATE_SMTP_SETTING, {
    onCompleted: () => {
      enqueueToast(
        enqueueSnackbar,
        translate(intl, "settings.smtp.saveSuccess")
      );
    }
  });

  const onSubmit = data => {
    const defaultLabIdAdmin = "admin";
    createSmtpSetting({
      variables: {
        labId: defaultLabIdAdmin,

        smtpSetting: {
          password: data.password,
          port: parseInt(data.port),
          sender: data.sender,
          smtpUrl: data.smtpUrl,
          ssl: data.ssl
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.root}>
        <CCPaper>
          <Loading open={loading} msg={"Loading..."} />
          <CCDialogTitle>{translate(intl, "settings.ui.smtp")}</CCDialogTitle>

          <CCDialogContent className={classes.content}>
            <Grid container direction={"column"}>
              <Grid item>
                <Grid container alignItems={"center"}>
                  <Grid item>
                    <Controller
                      name="smtpUrl"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error }
                      }) => (
                        <CVTextFieldDualType
                          permission={"smtpGeneralSetting"}
                          className={classes.input}
                          value={value}
                          label={`${intl.formatMessage({
                            id: "settings.smtp.smtpSetting"
                          })} *`}
                          variant={"outlined"}
                          margin={"dense"}
                          error={error?.message}
                          directInput={watch("direct")}
                          onChange={e => {
                            if (watch("direct")) {
                              onChange(e.target.value);
                              setValue("direct", !e.target.select);
                            } else {
                              let _index = SMTP_SERVER.findIndex(
                                item => item.value === e.target.value
                              );
                              if (_index > -1) {
                                onChange(e.target.value);
                                setValue("port", SMTP_SERVER[_index].port);
                                setValue("ssl", SMTP_SERVER[_index].ssl);
                              } else {
                                onChange(e.target.value);
                                setValue("direct", true);
                              }
                            }
                          }}
                          helperText={error?.message || ""}
                        >
                          {SMTP_SERVER.map(item => {
                            return (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </CVTextFieldDualType>
                      )}
                    />
                  </Grid>

                  <Grid item>
                    <Controller
                      name="ssl"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CVLabelCheckBox
                          permission={"smtpGeneralSetting"}
                          color={"secondary"}
                          checked={Boolean(value)}
                          onChange={e => {
                            onChange(e.target.checked);
                          }}
                          label={translate(intl, "settings.smtp.ssl")}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Controller
                  name="port"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => (
                    <CVTextField
                      permission={"smtpGeneralSetting"}
                      className={classes.input}
                      value={value}
                      label={`${translate(intl, "settings.smtp.port")} *`}
                      error={error?.message}
                      variant={"outlined"}
                      margin={"dense"}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^0-9\\.]+/g, "");
                        onChange({...e, target: { ...e.target, value } })
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 5
                        }
                      }}
                      helperText={error?.message || ""}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <Controller
                  name="sender"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => (
                    <CVTextField
                      permission={"smtpGeneralSetting"}
                      className={classes.input}
                      value={value}
                      label={`${translate(
                        intl,
                        "settings.smtp.emailAccount"
                      )} *`}
                      variant={"outlined"}
                      margin={"dense"}
                      error={error?.message}
                      onChange={e => onChange(stringToSlug(e.target.value))}
                      onBlur={e => onChange(e.target.value.trim())}
                      InputProps={{
                        inputProps: {
                          maxLength: ENTRY_LIMIT.EMAIL_FIELD,
                          autocomplete: "one-time-code"
                        }
                      }}
                      helperText={error?.message || ""}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <Grid container alignItems={"center"}>
                  <Grid item>
                    <Controller
                      name="password"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error }
                      }) => (
                        <CCTextField
                          permission={"smtpGeneralSetting"}
                          className={classes.input}
                          type={showPassword ? "text" : "password"}
                          value={value}
                          label={`${translate(
                            intl,
                            "settings.smtp.password"
                          )} *`}
                          error={error?.message}
                          variant={"outlined"}
                          margin={"dense"}
                          InputProps={{
                            inputProps: {
                              maxLength: 30,
                              autocomplete: "one-time-code"
                            },
                            endAdornment: (
                              <IconButton
                                style={{ padding: 5 }}
                                onClick={() => setShowPassword(prev => !prev)}
                              >
                                {showPassword ? (
                                  <InvisibleIcon
                                    style={{
                                      width: 20,
                                      height: 20,
                                      color: "#455A64"
                                    }}
                                  />
                                ) : (
                                  <VisibleIcon
                                    style={{
                                      width: 20,
                                      height: 20,
                                      color: "#455A64"
                                    }}
                                  />
                                )}
                              </IconButton>
                            )
                          }}
                          onChange={onChange}
                          onBlur={e => onChange(e.target.value.trim())}
                          helperText={error?.message || ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <CVButton
                      disabled={isSending}
                      permission={"smtpGeneralSetting"}
                      className={classes.help__button}
                      variant={"outlined"}
                      color={"normal"}
                      startIcon={
                        isSending ? (
                          <CircularProgress size={20} color="normal" />
                        ) : (
                          <SMSIcon />
                        )
                      }
                      onClick={handleSendEmail}
                    >
                      {intl.formatMessage({
                        id: "settings.smtp.sentTestMail"
                      })}
                    </CVButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CCDialogContent>

          <CCDialogActions>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item />

              <Grid item>
                <Grid container>
                  <Grid item>
                    <CCButton
                      variant={"text"}
                      color={"normal"}
                      onClick={onReset}
                    >
                      {translate(intl, "common.ui.cancel")}
                    </CCButton>
                  </Grid>
                  <Grid item>
                    <CVButton
                      type="submit"
                      permission={"smtpGeneralSetting"}
                      variant={"contained"}
                      color={"primary"}
                      startIcon={<CheckIcon />}
                    >
                      {translate(intl, "common.ui.save")}
                    </CVButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CCDialogActions>
        </CCPaper>
      </Box>
    </form>
  );
}
