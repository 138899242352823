const RearrangeIcon = props => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="11.5" cy="7.5" r="1.5" fill="currentColor" />
    <circle cx="11.5" cy="13.5" r="1.5" fill="currentColor" />
    <circle cx="11.5" cy="19.5" r="1.5" fill="currentColor" />
    <circle cx="16.5" cy="7.5" r="1.5" fill="currentColor" />
    <circle cx="16.5" cy="13.5" r="1.5" fill="currentColor" />
    <circle cx="16.5" cy="19.5" r="1.5" fill="currentColor" />
  </svg>
);

export default RearrangeIcon;
