import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import ReactModal from "react-modal";

const useStyles = makeStyles(theme => ({
  overlay: {
    zIndex: 1200,
    position: "fixed",
    inset: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "-webkit-tap-highlight-color": "transparent"
  },
  modal: {
    overflow: "hidden",
    outline: "none",
    borderRadius: theme.shape.borderRadius,
    border: 0,
    fontFamily: "SUIT Variable",
    width: props => (props?.width ? props.width : "auto"),
    height: props => (props?.height ? props.height : "auto"),

    "@media (min-width: 1280px)": {
      maxWidth: 1280
    }
  },
  header: {
    position: "relative",
    backgroundColor: theme.palette.background.dialogHeader,
    padding: "6px 20px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  headerTitle: {
    color: "#fff"
  },
  headerCloseBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: 4,
    color: "white",
    padding: 3
  },
  body: {
    background: "#fff",
    maxHeight: "calc(100vh - 170px)",
    overflowY: "auto"
  },
  footer: {
    background: "#fff",
    borderTop: `1px solid ${theme.palette.border.main}`,
    padding: "9px 12px",
    fontSize: 14,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  }
}));

const Modal = ({
  open,
  onClose,
  title,
  body,
  footer,
  width,
  height,
  classes: customClasses
}) => {
  const classes = useStyles({ width, height });

  return (
    <ReactModal
      isOpen={open}
      className={clsx(classes.modal, customClasses?.modal)}
      overlayClassName={clsx(classes.overlay, customClasses?.overlay)}
      onRequestClose={onClose}
    >
      {/* HEADER */}
      <Box className={clsx(classes.header, customClasses?.header)}>
        <Typography variant="h3" className={classes.headerTitle}>
          {title}
        </Typography>

        <IconButton
          aria-label="close"
          className={classes.headerCloseBtn}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "#fff" }} />
        </IconButton>
      </Box>
      {/* BODY */}
      <Box className={clsx(classes.body, customClasses?.body)}>{body}</Box>

      {/* FOOTER */}
      {footer && (
        <Box className={clsx(classes.footer, customClasses?.footer)}>
          {footer}
        </Box>
      )}
    </ReactModal>
  );
};

export default Modal;
